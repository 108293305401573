import { isNil } from 'lodash';
import { GeofenceType } from '../../common/types';
import { coordinatesToCoords } from '../../common/utils';
import { trace } from '../../configuration/setup/trace';
import { GenerateIsolineApiArg, IsolineRequest, IsolineResponse, facadeApi } from '../../store/facade/facadeApi';
import { fetchRangeFailed, fetchRangeTriggered, toggleTriggerRangeFetch } from '../../store/route/routeSlice';
import { changeBoundingBox } from '../../store/widget/actions/changeBoundingBox';
import { ShapeBuilder, renderShapes } from '../../store/widget/actions/renderShapes';

export const fetchIsoline = (isolineRequest: IsolineRequest, focusOnIsoline = false) => {
    return async (dispatch: Function, getState: Function) => {
        let response;
        const isolineParams: GenerateIsolineApiArg = { isolineRequest };
        try {
            dispatch(fetchRangeTriggered(true));
            response = await dispatch(facadeApi.endpoints.generateIsoline.initiate(isolineParams));
        } catch (error: any) {
            dispatch(fetchRangeFailed(error?.message));
            trace.error('could not fetch range', error);
            throw error;
        }

        if (response.data) {
            dispatch(fetchRangeTriggered(false));
            dispatch(toggleTriggerRangeFetch(false));

            const data = response.data as IsolineResponse;
            const isoline = data.items?.map((item) => item.points?.map(coordinatesToCoords));

            if (!isNil(isoline)) {
                const isolineShapes = isoline.map((isolinePoints) =>
                    new ShapeBuilder().withType(GeofenceType.Polygon).withPoints(isolinePoints).build()
                );

                renderShapes(...isolineShapes);
                if (focusOnIsoline) {
                    changeBoundingBox(isoline.flat(1));
                }
            }
        }
    };
};
