import { DeepPartial } from '@reduxjs/toolkit';
import { AssetDataResponse, VehicleType } from '../../../src/store/facade/facadeApi';

const generateRandomAssetName = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};
const allowedAssetTypes = [
    VehicleType.Truck,
    VehicleType.Bus,
    VehicleType.Van,
    VehicleType.Trailer,
    VehicleType.Undefined,
];

export const generateAssetMock = (): DeepPartial<AssetDataResponse> => ({
    asset_data: {
        asset_id: '0b3df596-da19-4f39-80b4-14a47dab7a24',
        account_id: '237c58e3-60b5-4442-a9ec-8641ea39325b',
        name: generateRandomAssetName(21),
        vehicleType: allowedAssetTypes[Math.floor(Math.random() * allowedAssetTypes.length)],
        occurred_at: '2024-01-19T11:45:26.951Z',
        temperature: -15,
        position: {
            latitude: 65.61074829101562,
            longitude: 18.723175048828125,
            altitude: 440.0,
            speed: 83.0,
        },
        state: {
            mileage: 74489,
            ignition_on: true,
            fuel_level: 28,
            remaining_range: 300,
            weight_total: 53719,
            state_of_charge: 50,
        },
        details: {
            details_bev: true,
        },
        driver_state: {
            driving_and_resting_details: {
                remaining_current_driving_time: 15000,
            },
        },
        weather: {
            description: 'Sunny. Frigid.',
            sky_description: 'Sunny',
            humidity: '85',
            temperature_summary: {
                temperature: -15,
                temperature_description: 'Frigid',
                high_temperature: '-15.50',
                low_temperature: '-25.10',
            },
            wind_summary: {
                air_description: '',
                wind_speed: 12,
                wind_description: 'West',
                wind_short_description: 'W',
            },
        },
    },
});
