import Fade from '@rio-cloud/rio-uikit/Fade';
import { gt } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../common/constants';
import { DownloadPDF } from '../../components/DownloadPDF/DownloadPDF';
import { useAppSelector } from '../../configuration/setup/hooks';
import { SectionsContainer } from '../../containers/SectionsContainer/SectionsContainer';
import { TourAlternatives } from '../../containers/TourAlternatives/TourAlternatives';
import { TourSummary } from '../../containers/TourSummary/TourSummary';
import { selectShowSummary } from '../../store/app/appSelectors';
import { selectSelectedRoute, selectSuggestedRoutes } from '../../store/route/routeSelectors';

export const RouteSummaryPage = () => {
    const navigate = useNavigate();

    const showContent = useAppSelector(selectShowSummary);

    const suggestedRoutes = useAppSelector(selectSuggestedRoutes);
    const trip = useAppSelector(selectSuggestedRoutes);
    const selectedRoute = useAppSelector(selectSelectedRoute);
    const selectedRouteData = trip?.routes[selectedRoute!];

    return (
        <Fade
            animationStyle={Fade.PAGE_BACK}
            show={showContent}
            onExitComplete={() => navigate(DEFAULT_ROUTE, { replace: false })}
        >
            <div className="display-flex flex-column" data-testid="route-summary-layout">
                {trip && (
                    <div className="padding-15 display-flex flex-column gap-25" data-testid="route-summary-tabs">
                        {gt(suggestedRoutes!.routes.length, 1) && <TourAlternatives routes={trip.routes} />}

                        <TourSummary trip={trip} selectedRoute={selectedRoute!} />

                        {selectedRouteData && <SectionsContainer route={selectedRouteData} />}
                    </div>
                )}
            </div>
            <div className="display-flex justify-content-end padding-10" data-testid="route-widget-download">
                <DownloadPDF />
            </div>
        </Fade>
    );
};
