import { gt, isNil, uniqueId } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { convertSecondsIntoHHmm, convertStringToDateFormat } from '../../../common/utils';
import { AdditionalStopSection } from '../../../store/facade/facadeApi';
import { CurrentStatus } from '../SectionsContainer';

type StopoverPanelProps = {
    stopover: AdditionalStopSection;
} & CurrentStatus;

export const StopoverPanel = ({ stopover }: StopoverPanelProps) => {
    const date = convertStringToDateFormat(stopover.estimated_arrival_time);

    return (
        <li key={uniqueId()} className="timeline-element" data-testid="timeline-element">
            <div className="timeline-date margin-right-10">
                <div className="border border-width-3 border-color-white">
                    <span className="badge badge-lg badge-muted padding-5">
                        <span className="rioglyph rioglyph-stopover" />
                    </span>
                </div>
            </div>
            <div className="timeline-content margin-bottom-15">
                <div className="text-medium text-size-16 text-color-darker margin-top-5 margin-bottom-5">
                    <FormattedMessage id="intl-msg:smartRoute.tour.stopOver" />
                </div>
                <div className="display-flex flex-column gap-2">
                    {gt(stopover.duration, 0) && (
                        <div>
                            <span className="rioglyph rioglyph-time text-color-gray margin-right-5" />
                            {!isNil(stopover.duration) ? <span>{convertSecondsIntoHHmm(stopover.duration)}</span> : '-'}
                        </div>
                    )}
                    <div>
                        <span className="rioglyph rioglyph-calendar text-color-gray margin-right-5" />
                        <span>{date}</span>
                    </div>

                    <div>
                        <span className="rioglyph rioglyph-map-marker text-color-gray margin-right-5" />
                        <span>{stopover.address}</span>
                    </div>
                </div>
            </div>
        </li>
    );
};
