import { supportedCurrencies } from '../../../src/common/supportedCurrencies';
import { SectionType } from '../../../src/store/facade/facadeApi';

export const generateEmptyRouteMock = () => ({
    asset_id: '',
    timestamp: '2024-04-03T14:50:32Z',
    overview: {
        fastest_route: '',
        cheapest_route: '',
        shortest_route: '',
    },
    routes: [],
});

export const generateRouteMock = () => ({
    asset_id: '',
    timestamp: '2023-09-11T07:08:09.789Z',
    overview: {
        fastest_route: '517d3123-4a8f-4aa4-b07e-4b5b39b7abc',
        cheapest_route: '517d3123-4a8f-4aa4-b07e-4b5b39b7def',
        shortest_route: '517d3123-4a8f-4aa4-b07e-4b5b39b7ghi',
    },
    routes: [
        {
            id: '517d3123-4a8f-4aa4-b07e-4b5b39b7abc', // fastest_route
            summary: {
                distance: 315269, // meters
                duration: 13500, // seconds
                total_cost: 60.03, // €
                vehicle_cost: 10.01, // €
                toll_cost: 20.01, // €
                energy_cost: 30.01, // €
                total_consumption: 422.09, // kwh
                charging_duration: 50.0, // minutes
                co2_savings: 134.18, // kg
            },
            sections: [
                {
                    sectionType: SectionType.Departure,
                    info: {
                        departure_time: '2023-09-11T08:00:00.000Z',
                        address: '1800 Lisboa, Portugal',
                        slow_traffic: false,
                        soc_percentage: 97,
                        location: {
                            latitude: 38.726334,
                            longitude: -9.14952,
                        },
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T08:00:00.000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 38.726334,
                                longitude: -9.14952,
                            },
                            {
                                latitude: 39.544692,
                                longitude: -8.630746,
                            },
                        ],
                        nearChargingStations: [
                            {
                                id: '20b864c4-8af1-4399-8b1b-47584707c620',
                                operator: {
                                    id: '9cf0ecc9-8996-4da3-b88f-769175a414eb',
                                    name: 'IONITY GmbH',
                                },
                                name: 'IONITY Haut Koenigsbourg - CCS #01',
                                address: 'A35, Aire de Haut Koenigsbourg',
                                city: 'Orschwiller',
                                postal_code: '67600',
                                country_code: 'FR',
                                location: {
                                    latitude: 40.62124,
                                    longitude: -8.66079,
                                },
                                max_power: 350,
                                truck_charging_grade: 2,
                                support_phone: '',
                                connectors: [
                                    {
                                        id: '1328add4-399d-4307-a65c-e326f90298dc',
                                        msp_connector_id: '',
                                        evse_id: 'FR*IOY*E413801',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 350,
                                        truck_charging_grade: 2,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                ],
                            },
                            {
                                id: 'd653bc9f-e4b9-4492-875d-e027baf3e06c',
                                operator: {
                                    id: '83f23926-43ae-4e43-8b3c-d2ad60cc1fb8',
                                    name: 'Proviridis',
                                },
                                name: 'Proviridis/6154153126b5d1a1ca0d1a42',
                                address: "Rue de l'Argentique 14",
                                city: 'Fragnes-La Loyère',
                                postal_code: '71530',
                                country_code: 'FR',
                                location: {
                                    latitude: 40.133908,
                                    longitude: -8.490199,
                                },
                                max_power: 225,
                                truck_charging_grade: 4,
                                support_phone: '',
                                connectors: [
                                    {
                                        id: '83d043df-668a-4969-8981-e6b03d15e3c8',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*D01*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                    {
                                        id: 'd706eefc-bf94-445f-996a-02a1e4bc2fa8',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*AD03*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                    {
                                        id: '91e1d46e-1f01-48b7-a1dc-394d63315b07',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*D02*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.ChargingStation,
                    info: {
                        charging_station: {
                            id: 'be69fd77-be1d-42f3-a0e2-a0f50c78e5f2',
                            operator: {
                                id: '90c582ef-2744-489b-8e17-13374c93cc9f',
                                name: 'TOTAL EV CHARGE SERVICES SAS',
                            },
                            name: 'RELAIS POITOU CHARENTES SUD',
                            address: '2490 Fatima, Portugal',
                            city: 'VOUILLE',
                            postal_code: '79230',
                            country_code: 'FR',
                            location: {
                                latitude: 39.544692,
                                longitude: -8.630746,
                            },
                            max_power: 175,
                            truck_charging_grade: 2,
                            support_phone: '',
                            connectors: [
                                {
                                    id: 'a296d8d4-d1af-454a-9b92-d2fc378e29c3',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*005*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'd5a417bb-d680-429f-ba0b-f3ef4015af19',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*007*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'ea5a1aec-9ea5-48ff-8118-ac0a3d2fd1f0',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*006*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: '7c16e4f8-882f-4931-b3af-264dbfece56c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*003*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'fee2bd4d-b987-4231-872d-c412ce4a9f8c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*002*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 2,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'dfc9142f-5926-47c8-b3f6-17c1c07addf4',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*004*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                            ],
                        },
                        estimated_arrival_time: '2023-09-11T09:24:34.000Z',
                        duration: 120,
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T10:18:55.000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 39.544692,
                                longitude: -8.630746,
                            },
                            {
                                latitude: 40.2071,
                                longitude: -8.4294,
                            },
                        ],
                        nearChargingStations: [
                            {
                                id: '20b864c4-8af1-4399-8b1b-47584707c620',
                                operator: {
                                    id: '9cf0ecc9-8996-4da3-b88f-769175a414eb',
                                    name: 'IONITY GmbH',
                                },
                                name: 'IONITY Haut Koenigsbourg - CCS #01',
                                address: 'A35, Aire de Haut Koenigsbourg',
                                city: 'Orschwiller',
                                postal_code: '67600',
                                country_code: 'FR',
                                location: {
                                    latitude: 40.62124,
                                    longitude: -8.66079,
                                },
                                max_power: 350,
                                truck_charging_grade: 2,
                                support_phone: '',
                                connectors: [
                                    {
                                        id: '1328add4-399d-4307-a65c-e326f90298dc',
                                        msp_connector_id: '',
                                        evse_id: 'FR*IOY*E413801',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 350,
                                        truck_charging_grade: 2,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                ],
                            },
                            {
                                id: 'd653bc9f-e4b9-4492-875d-e027baf3e06c',
                                operator: {
                                    id: '83f23926-43ae-4e43-8b3c-d2ad60cc1fb8',
                                    name: 'Proviridis',
                                },
                                name: 'Proviridis/6154153126b5d1a1ca0d1a42',
                                address: "Rue de l'Argentique 14",
                                city: 'Fragnes-La Loyère',
                                postal_code: '71530',
                                country_code: 'FR',
                                location: {
                                    latitude: 40.133908,
                                    longitude: -8.490199,
                                },
                                max_power: 225,
                                truck_charging_grade: 4,
                                support_phone: '',
                                connectors: [
                                    {
                                        id: '83d043df-668a-4969-8981-e6b03d15e3c8',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*D01*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                    {
                                        id: 'd706eefc-bf94-445f-996a-02a1e4bc2fa8',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*AD03*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                    {
                                        id: '91e1d46e-1f01-48b7-a1dc-394d63315b07',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*D02*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.AdditionalStop,
                    info: {
                        duration: 15269,
                        estimated_arrival_time: '2023-09-11T14:16:08.000Z',
                        address: '3000 Coimbra, Portugal',
                        location: {
                            latitude: 40.2071,
                            longitude: -8.4294,
                        },
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T16:18:55.000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 40.2071,
                                longitude: -8.4294,
                            },
                            {
                                latitude: 40.816267,
                                longitude: -8.474842,
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.ChargingStation,
                    info: {
                        charging_station: {
                            id: 'be69fd77-be1d-42f3-a0e2-a0f50c78e5f2',
                            operator: {
                                id: '90c582ef-2744-489b-8e17-13374c93cc9f',
                                name: 'TOTAL EV CHARGE SERVICES SAS',
                            },
                            name: 'RELAIS POITOU CHARENTES SUD',
                            address: '3850 Albergaria-a-Velha, Portugal',
                            city: 'VOUILLE',
                            postal_code: '79230',
                            country_code: 'FR',
                            location: {
                                latitude: 40.816267,
                                longitude: -8.474842,
                            },
                            max_power: 175,
                            truck_charging_grade: 2,
                            support_phone: '',
                            connectors: [
                                {
                                    id: 'a296d8d4-d1af-454a-9b92-d2fc378e29c3',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*005*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'd5a417bb-d680-429f-ba0b-f3ef4015af19',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*007*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'ea5a1aec-9ea5-48ff-8118-ac0a3d2fd1f0',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*006*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: '7c16e4f8-882f-4931-b3af-264dbfece56c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*003*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'fee2bd4d-b987-4231-872d-c412ce4a9f8c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*002*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 2,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'dfc9142f-5926-47c8-b3f6-17c1c07addf4',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*004*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                            ],
                        },
                        estimated_arrival_time: '2023-09-11T16:58:41.000Z',
                        duration: 120,
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T17:47:32.000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 40.816267,
                                longitude: -8.474842,
                            },
                            {
                                latitude: 41.14584,
                                longitude: -8.610815,
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.Arrival,
                    info: {
                        estimated_arrival_time: '2023-09-11T19:35:21.000Z',
                        address: '8500 Porto, Portugal',
                        location: {
                            latitude: 41.14584,
                            longitude: -8.610815,
                        },
                    },
                },
            ],
        },
        {
            id: '517d3123-4a8f-4aa4-b07e-4b5b39b7def', // shortest_route
            summary: {
                distance: 313269, // meters
                duration: 13100, // seconds
                total_cost: 80.03, // €
                vehicle_cost: 10.01, // €
                toll_cost: 20.01, // €
                energy_cost: 50.01, // €
                vehicle_savings: 36.72, // €
                energy_savings: 28.38, // €
                total_consumption: 167.67, // kwh
                charging_duration: 90.0, // minutes
                co2_savings: 345.62, // kg
                currency: supportedCurrencies[0], // kg
            },
            sections: [
                {
                    sectionType: SectionType.Departure,
                    info: {
                        departure_time: '2023-09-11T08:00:00.000Z',
                        address: '1800 Lisboa, Portugal',
                        slow_traffic: false,
                        soc_percentage: 97,
                        location: {
                            latitude: 38.726334,
                            longitude: -9.14952,
                        },
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T08:00:000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 38.726334,
                                longitude: -9.14952,
                            },
                            {
                                latitude: 39.495162,
                                longitude: -8.761774,
                            },
                        ],
                        nearChargingStations: [
                            {
                                id: '20b864c4-8af1-4399-8b1b-47584707c620',
                                operator: {
                                    id: '9cf0ecc9-8996-4da3-b88f-769175a414eb',
                                    name: 'IONITY GmbH',
                                },
                                name: 'IONITY Haut Koenigsbourg - CCS #01',
                                address: 'A35, Aire de Haut Koenigsbourg',
                                city: 'Orschwiller',
                                postal_code: '67600',
                                country_code: 'FR',
                                location: {
                                    latitude: 40.62124,
                                    longitude: -8.66079,
                                },
                                max_power: 350,
                                truck_charging_grade: 2,
                                support_phone: '',
                                connectors: [
                                    {
                                        id: '1328add4-399d-4307-a65c-e326f90298dc',
                                        msp_connector_id: '',
                                        evse_id: 'FR*IOY*E413801',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 350,
                                        truck_charging_grade: 2,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                ],
                            },
                            {
                                id: 'd653bc9f-e4b9-4492-875d-e027baf3e06c',
                                operator: {
                                    id: '83f23926-43ae-4e43-8b3c-d2ad60cc1fb8',
                                    name: 'Proviridis',
                                },
                                name: 'Proviridis/6154153126b5d1a1ca0d1a42',
                                address: "Rue de l'Argentique 14",
                                city: 'Fragnes-La Loyère',
                                postal_code: '71530',
                                country_code: 'FR',
                                location: {
                                    latitude: 40.133908,
                                    longitude: -8.490199,
                                },
                                max_power: 225,
                                truck_charging_grade: 4,
                                support_phone: '',
                                connectors: [
                                    {
                                        id: '83d043df-668a-4969-8981-e6b03d15e3c8',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*D01*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                    {
                                        id: 'd706eefc-bf94-445f-996a-02a1e4bc2fa8',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*AD03*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                    {
                                        id: '91e1d46e-1f01-48b7-a1dc-394d63315b07',
                                        msp_connector_id: '',
                                        evse_id: 'FR*PVD*EVG*FRA71*D02*1',
                                        status: 'UNKNOWN',
                                        standard: 'IEC_62196_T2_COMBO',
                                        plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                        power_type: 'DC',
                                        max_power: 225,
                                        truck_charging_grade: 4,
                                        msp_price_status: '',
                                        prices: [],
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.ChargingStation,
                    info: {
                        charging_station: {
                            id: 'be69fd77-be1d-42f3-a0e2-a0f50c78e5f2',
                            operator: {
                                id: '90c582ef-2744-489b-8e17-13374c93cc9f',
                                name: 'TOTAL EV CHARGE SERVICES SAS',
                            },
                            name: 'RELAIS POITOU CHARENTES SUD',
                            address: '2855 Serra De Aire, Portugal',
                            city: 'VOUILLE',
                            postal_code: '79230',
                            country_code: 'FR',
                            location: {
                                latitude: 39.495162,
                                longitude: -8.761774,
                            },
                            max_power: 175,
                            truck_charging_grade: 2,
                            support_phone: '',
                            connectors: [
                                {
                                    id: 'a296d8d4-d1af-454a-9b92-d2fc378e29c3',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*005*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'd5a417bb-d680-429f-ba0b-f3ef4015af19',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*007*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'ea5a1aec-9ea5-48ff-8118-ac0a3d2fd1f0',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*006*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: '7c16e4f8-882f-4931-b3af-264dbfece56c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*003*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'fee2bd4d-b987-4231-872d-c412ce4a9f8c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*002*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 2,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'dfc9142f-5926-47c8-b3f6-17c1c07addf4',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*004*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                            ],
                        },
                        estimated_arrival_time: '2023-09-11T09:14:34.000Z',
                        duration: 120,
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T10:37:32.000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 39.495162,
                                longitude: -8.761774,
                            },
                            {
                                latitude: 39.904522,
                                longitude: -8.580738,
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.AdditionalStop,
                    info: {
                        duration: 15269,
                        estimated_arrival_time: '2023-09-11T13:16:08.000Z',
                        address: '3100 Pombal, Portugal',
                        location: {
                            latitude: 39.904522,
                            longitude: -8.580738,
                        },
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T15:03:28.000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 39.904522,
                                longitude: -8.580738,
                            },
                            {
                                latitude: 40.629022,
                                longitude: -8.616218,
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.ChargingStation,
                    info: {
                        charging_station: {
                            id: 'be69fd77-be1d-42f3-a0e2-a0f50c78e5f2',
                            operator: {
                                id: '90c582ef-2744-489b-8e17-13374c93cc9f',
                                name: 'TOTAL EV CHARGE SERVICES SAS',
                            },
                            name: 'RELAIS POITOU CHARENTES SUD',
                            address: '3800 Aveiro, Portugal',
                            city: 'VOUILLE',
                            postal_code: '79230',
                            country_code: 'FR',
                            location: {
                                latitude: 40.629022,
                                longitude: -8.616218,
                            },
                            max_power: 175,
                            truck_charging_grade: 2,
                            support_phone: '',
                            connectors: [
                                {
                                    id: 'a296d8d4-d1af-454a-9b92-d2fc378e29c3',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*005*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'd5a417bb-d680-429f-ba0b-f3ef4015af19',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*007*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'ea5a1aec-9ea5-48ff-8118-ac0a3d2fd1f0',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*006*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: '7c16e4f8-882f-4931-b3af-264dbfece56c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*003*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'fee2bd4d-b987-4231-872d-c412ce4a9f8c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*002*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 2,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'dfc9142f-5926-47c8-b3f6-17c1c07addf4',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*004*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                            ],
                        },
                        estimated_arrival_time: '2023-09-11T16:58:41.000Z',
                        duration: 120,
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T17:58:39.000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 40.629022,
                                longitude: -8.616218,
                            },
                            {
                                latitude: 41.14584,
                                longitude: -8.610815,
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.Arrival,
                    info: {
                        estimated_arrival_time: '2023-09-11T20:25:21.000Z',
                        address: '8500 Porto, Portugal',
                        location: {
                            latitude: 41.14584,
                            longitude: -8.610815,
                        },
                    },
                },
            ],
        },
        {
            id: '517d3123-4a8f-4aa4-b07e-4b5b39b7ghi', // cheapest_route
            summary: {
                distance: 317269, // meters
                duration: 13900, // second
                total_cost: 60.03, // €
                vehicle_cost: 10.01, // €
                toll_cost: 10.01, // €
                energy_cost: 40.01, // €
                total_consumption: 549.34, // kwh
                charging_duration: 30.0, // minutes
                co2_savings: 89.76, // kg
            },
            sections: [
                {
                    sectionType: SectionType.Departure,
                    info: {
                        departure_time: '2023-09-11T08:00:00.000Z',
                        address: '1800 Lisboa, Portugal',
                        slow_traffic: false,
                        soc_percentage: 97,
                        location: {
                            latitude: 38.726334,
                            longitude: -9.14952,
                        },
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T13:19:43Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 38.726334,
                                longitude: -9.14952,
                            },
                            {
                                latitude: 38.72966,
                                longitude: -9.14734,
                            },
                            {
                                latitude: 39.8584,
                                longitude: -8.815229,
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.ChargingStation,
                    info: {
                        charging_station: {
                            id: 'be69fd77-be1d-42f3-a0e2-a0f50c78e5f2',
                            operator: {
                                id: '90c582ef-2744-489b-8e17-13374c93cc9f',
                                name: 'TOTAL EV CHARGE SERVICES SAS',
                            },
                            name: 'RELAIS POITOU CHARENTES SUD',
                            address: '7000 Leiria, Portugal',
                            city: 'VOUILLE',
                            postal_code: '79230',
                            country_code: 'FR',
                            location: {
                                latitude: 39.8584,
                                longitude: -8.815229,
                            },
                            max_power: 175,
                            truck_charging_grade: 2,
                            support_phone: '',
                            connectors: [
                                {
                                    id: 'a296d8d4-d1af-454a-9b92-d2fc378e29c3',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*005*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'd5a417bb-d680-429f-ba0b-f3ef4015af19',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*007*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'ea5a1aec-9ea5-48ff-8118-ac0a3d2fd1f0',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*006*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: '7c16e4f8-882f-4931-b3af-264dbfece56c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*003*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'fee2bd4d-b987-4231-872d-c412ce4a9f8c',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*002*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 2,
                                    msp_price_status: '',
                                    prices: [],
                                },
                                {
                                    id: 'dfc9142f-5926-47c8-b3f6-17c1c07addf4',
                                    msp_connector_id: '',
                                    evse_id: 'FR*HPC*ENF078016*004*1',
                                    status: 'UNKNOWN',
                                    standard: 'IEC_62196_T2_COMBO',
                                    plugType: 'CCS Combo 2 Plug (Cable Attached)',
                                    power_type: 'DC',
                                    max_power: 175,
                                    truck_charging_grade: 1,
                                    msp_price_status: '',
                                    prices: [],
                                },
                            ],
                        },
                        estimated_arrival_time: '2023-09-11T16:08:31.000Z',
                        duration: 120,
                    },
                },
                {
                    sectionType: SectionType.Driving,
                    info: {
                        estimated_departure: '2023-09-11T17:47:32.000Z',
                        estimated_duration: 14584,
                        distance: 315269,
                        coordinates: [
                            {
                                latitude: 39.8584,
                                longitude: -8.815229,
                            },
                            {
                                latitude: 41.14584,
                                longitude: -8.610815,
                            },
                        ],
                    },
                },
                {
                    sectionType: SectionType.Arrival,
                    info: {
                        estimated_arrival_time: '2023-09-11T20:45:21.000Z',
                        address: '8500 Porto, Portugal',
                        location: {
                            latitude: 41.14584,
                            longitude: -8.610815,
                        },
                    },
                },
            ],
        },
    ],
});
