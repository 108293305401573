import { useSortable } from '@dnd-kit/sortable';
import { CSS as cssDndKit } from '@dnd-kit/utilities';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { isNil, isUndefined } from 'lodash';
import getOr from 'lodash/fp/getOr';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectCurrentStep } from '../../store/app/appSelectors';
import { NavigationStep } from '../../store/app/appSlice';
import { useGetAssetDataQuery } from '../../store/facade/facadeApi';
import { selectShowVehicleRange } from '../../store/route/routeSelectors';
import { toggleTriggerRangeFetch } from '../../store/route/routeSlice';
import {
    selectSuggestedAddresses,
    selectWaypointOnFocus,
    selectWaypointsOrder,
} from '../../store/search/searchSelectors';
import {
    Waypoint,
    WaypointType,
    suggestedAddressesChanged,
    waypointAdded,
    waypointChanged,
    waypointOnFocusChanged,
    waypointRemoved,
} from '../../store/search/searchSlice';
import { selectVehicleProfile } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { fetchAddresses } from '../fetchData/fetchAddresses';
import { fetchRoutes } from '../fetchData/fetchRoutes';
import { AddWaypointButton } from './AddWaypointButton';
import { WaypointInput } from './WaypointInput';

interface Suggestion {
    label: string;
    position: Coordinates;
    resultType: string;
    title: string;
    type: WaypointType;
    station_id: string;
}

interface Coordinates {
    lat: number;
    lng: number;
}

type WaypointListItemProps = {
    value: Waypoint;
    orderIndex: number;
    allowRemove: boolean;
    totalWaypoints: number;
};

const getIcon = (index: number, max: number, waypoint: Waypoint) => {
    if (index === 0) {
        return 'start';
    } else if (index === max) {
        return 'finish';
    } else if (waypoint.type === WaypointType.CHARGING_STATION) {
        return 'filling-e-station';
    } else if (waypoint.type === WaypointType.ADDITIONAL_STOP) {
        return 'arrow-down';
    }
    return 'arrow-down';
};

export const WaypointListItem = (props: WaypointListItemProps) => {
    const { value, orderIndex, allowRemove, totalWaypoints } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const currentStep = useAppSelector(selectCurrentStep);

    const showVehicleRange = useAppSelector(selectShowVehicleRange);
    const waypointOnFocus = useAppSelector(selectWaypointOnFocus);
    const suggestedAddresses = useAppSelector(selectSuggestedAddresses);
    const waypointsOrder = useAppSelector(selectWaypointsOrder);
    const assetIdLocked = useAppSelector(selectVehicleProfile).assetLocked?.asset_id;

    const { currentData: vehicleData } = useGetAssetDataQuery(
        { assetId: assetIdLocked! },
        { skip: isNil(assetIdLocked) }
    );

    const vehiclePosition = {
        lat: vehicleData?.asset_data?.position?.latitude,
        lng: vehicleData?.asset_data?.position?.longitude,
    };

    const waypoint = value;
    const waypointId = waypoint.id;
    const addresses = getOr([], waypointId, suggestedAddresses);

    const { setNodeRef, transform, transition, listeners } = useSortable({ id: waypointId });
    const style = {
        transform: cssDndKit.Transform.toString(transform),
        transition,
    };

    useEffect(() => {
        // Reset state of first waypoint input and update suggestions when asset changes
        const id = waypointsOrder[0];
        const isFirstWaypoint = value.id === waypointsOrder[0];
        let position;
        if (assetIdLocked && !isUndefined(vehiclePosition.lat) && !isUndefined(vehiclePosition.lng)) {
            position = vehiclePosition;
        }
        if (isFirstWaypoint) {
            // Clear 'use Vehicle Location' from WaypointInput if new selected vehicle has no position
            if (isUndefined(position) && value.type === WaypointType.VEHICLE_LOCATION) {
                dispatch(
                    waypointChanged({
                        id,
                        address: '',
                        type: WaypointType.ADDITIONAL_STOP,
                    } as Waypoint)
                );
                dispatch(
                    suggestedAddressesChanged({
                        addresses: [],
                        id,
                    })
                );
            } else {
                // Update suggestions based on new selected vehicle
                dispatch(fetchAddresses(id, value.address ?? '', isFirstWaypoint, position, intl));
            }
        }
    }, [vehicleData, assetIdLocked]);

    const handleInputValueChanged = (id: number, address: string, type: WaypointType) => {
        handleDeleteSuggestedRoutes(id);
        dispatch(
            waypointChanged({
                id,
                address,
                type: type ?? WaypointType.ADDITIONAL_STOP,
            } as Waypoint)
        );

        let position;
        const isFirstWaypoint = id === waypointsOrder[0];
        if (assetIdLocked && !isUndefined(vehiclePosition.lat) && !isUndefined(vehiclePosition.lng)) {
            position = vehiclePosition;
        }

        dispatch(fetchAddresses(id, address, isFirstWaypoint, position, intl));

        if (showVehicleRange && waypointOnFocus === id) {
            dispatch(toggleTriggerRangeFetch(true));
        }
    };

    const handleDeleteSuggestedRoutes = (id: number) => dispatch(suggestedAddressesChanged({ id, addresses: [] }));

    const mapSuggestionType = (suggestionType: string) => {
        if (suggestionType === 'charging-station') {
            return WaypointType.CHARGING_STATION;
        } else {
            return WaypointType.ADDITIONAL_STOP;
        }
    };

    const fetchIsolineForPrevWp = () => {
        const wpIndex = waypointsOrder.indexOf(waypointId);
        dispatch(waypointOnFocusChanged(waypointsOrder[wpIndex - 1]));
        dispatch(toggleTriggerRangeFetch(true));
    };

    const handleSuggestionSelected = async (id: number, suggestion: Suggestion) => {
        handleDeleteSuggestedRoutes(id);
        const newWaypoint = {
            id,
            address: suggestion.label,
            coordinates: { ...suggestion.position },
            type: mapSuggestionType(suggestion.resultType),
            station_id: suggestion.station_id,
        };
        dispatch(waypointChanged(newWaypoint));

        if (currentStep === NavigationStep.StopsStep) {
            try {
                await dispatch(fetchRoutes);
            } catch (error) {
                Notification.info(
                    intl.formatMessage({ id: 'intl-msg:smartRoute.notification.pleaseChangeRouteSettings' }),
                    intl.formatMessage({ id: 'intl-msg:smartRoute.notification.routeCalculationFailed' })
                );
            }
        }
    };

    const handleInputCleared = (id: number) => {
        fetchIsolineForPrevWp();
        handleDeleteSuggestedRoutes(id);
    };

    const handleWaypointAdd = () => dispatch(waypointAdded(waypointId));

    const handleWaypointRange = (id: number) => dispatch(waypointOnFocusChanged(id));

    const handleWaypointRemove = async () => {
        fetchIsolineForPrevWp();
        dispatch(waypointRemoved(waypointId));
        if (currentStep === NavigationStep.StopsStep) {
            try {
                await dispatch(fetchRoutes);
            } catch (error) {
                Notification.info(
                    intl.formatMessage({ id: 'intl-msg:smartRoute.notification.pleaseChangeRouteSettings' }),
                    intl.formatMessage({ id: 'intl-msg:smartRoute.notification.routeCalculationFailed' })
                );
            }
        }
    };

    return (
        <div ref={setNodeRef} style={style} {...listeners}>
            <WaypointInput
                waypoint={waypoint}
                icon={getIcon(orderIndex, totalWaypoints, waypoint)}
                suggestedAddresses={addresses}
                onInputValueChanged={handleInputValueChanged}
                onSuggestionSelected={handleSuggestionSelected}
                onInputCleared={handleInputCleared}
                onShowRange={handleWaypointRange}
                onRemove={handleWaypointRemove}
                allowRemove={allowRemove}
            />
            <AddWaypointButton onClick={handleWaypointAdd} />
        </div>
    );
};
