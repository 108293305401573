import { Action } from '@reduxjs/toolkit';
import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';
import { Dispatch } from 'redux';
import { fetchData } from '../../configuration/setup/fetch';
import { RootState } from '../../configuration/setup/store';
import { selectHereSettings } from '../../store/data/dataSelectors';
import { selectLocale } from '../../store/lang/langSelectors';

const REVERSE_GEOCODING_ENTPOINT = 'https://revgeocode.search.hereapi.com/v1/revgeocode';

interface ReverseGeocodeOptions {
    prox: string;
    language: string;
    apikey: string;
}

const reverseGeocode = async (options: ReverseGeocodeOptions) => {
    const { apikey, prox: rawProx, language } = options;
    const prox = rawProx.replace(',', '%2C');

    // eslint-disable-next-line max-len
    const url = `${REVERSE_GEOCODING_ENTPOINT}?apikey=${apikey}&at=${prox}&type=address&limit=1&lang=${language}`;

    const response = await fetchData(url);

    const firstResult = get('items[0]')(response);

    if (firstResult) {
        return firstResult.address.label;
    } else {
        throw new Error('No corresponding address for the given coordinates!');
    }
};

export const resolveAddress = (action: Action<any>) => async (dispatch: Dispatch, getState: () => RootState) => {
    const coordinates = get('payload.coordinates')(action);
    if (isNil(coordinates) || isNil(coordinates.lat) || isNil(coordinates.lng)) {
        return;
    }

    const apikey = selectHereSettings(getState())?.apikey;
    if (isNil(apikey)) {
        return;
    }

    const language = selectLocale(getState());
    const prox = `${coordinates.lat},${coordinates.lng}`;

    try {
        const address = await reverseGeocode({ apikey, prox, language });
        dispatch({
            ...action,
            payload: {
                ...action.payload,
                address,
            },
        });
        return address;
    } catch (e) {
        dispatch(action);
    }
};
