import { Document, Page, Text, View } from '@react-pdf/renderer';
import { Unit } from '../../common/constants';
import { convertMetersIntoKilometers, convertSecondsIntoHHmm, convertStringToDateFormat } from '../../common/utils';
import {
    AdditionalStopSection,
    ArrivalSection,
    ChargingStationSection,
    DepartureSection,
    DrivingSection,
    RouteSection,
    SectionType,
} from '../../store/facade/facadeApi';
import { RouteConstraints } from '../../store/route/routeSlice';
import { Waypoint } from '../../store/search/searchSlice';
import { styles } from './StyleSheet';
import { generateTripSection } from './TripSectionsPage';

export const RouteDownloadPage = (props: RouteDownloadPageProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.subTitle}>Trip summary</Text>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Vehicle name</Text>
                        <Text>{props.assetName}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Vehicle type</Text>
                        <Text>{props.assetType.toLowerCase()}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Vehicle engine</Text>
                        <Text>{props.assetEngine ? 'electric' : 'diesel'}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Vehicle profile</Text>
                        <Text>{props.assetProfile.toLowerCase()}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Dangerous goods classification</Text>
                        <Text>{props.assetHazardClass}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Cargo weight</Text>
                        <Text>
                            {props.assetCargo}
                            {Unit.weight}
                        </Text>
                    </View>
                    {props.assetEngine ? (
                        <View style={styles.sectionItem}>
                            <Text style={styles.sectionColumn}>Battery capacity</Text>
                            <Text>
                                {props.assetBatteryCapacity}
                                {Unit.KWH}
                            </Text>
                        </View>
                    ) : (
                        <View style={styles.sectionItem}>
                            <Text style={styles.sectionColumn}>Emission class</Text>
                            <Text>{props.assetEmissionClass}</Text>
                        </View>
                    )}
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Tunnel category</Text>
                        <Text>{props.assetTunnelCategory}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Tour departure time</Text>
                        <Text>{props.tourDepartureDateTime}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Tour distance</Text>
                        <Text>
                            {props.tourDistance}
                            {Unit.km}
                        </Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Tour duration</Text>
                        <Text>{props.tourDuration}</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.subTitle}>Restrictions</Text>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Avoid Access Highway</Text>
                        <Text>{props.routeConstraints?.isAvoidAccessHighway ? 'Yes' : 'No'}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Avoid Toll Roads</Text>
                        <Text>{props.routeConstraints?.isAvoidTollRoads ? 'Yes' : 'No'}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Avoid Ferry</Text>
                        <Text>{props.routeConstraints?.isAvoidFerry ? 'Yes' : 'No'}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Avoid Tunnel</Text>
                        <Text>{props.routeConstraints?.isAvoidTunnel ? 'Yes' : 'No'}</Text>
                    </View>
                    <View style={styles.sectionItem}>
                        <Text style={styles.sectionColumn}>Avoid Route Alternatives</Text>
                        <Text>{props.routeConstraints?.isAvoidRouteAlternatives ? 'Yes' : 'No'}</Text>
                    </View>
                </View>
                <View style={styles.pageCreation}>
                    <Text
                        style={styles.pageCreation}
                        render={() => `Created at: ${props.tourPlanningDate} ${props.tourPlanningTime}`}
                        fixed
                    />
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <Text style={styles.title}>Trip sections</Text>

                {props.tripSections?.map((section) => {
                    switch (section.sectionType) {
                        case SectionType.Departure:
                            const departure = section.info as DepartureSection;
                            const departureDate = convertStringToDateFormat(departure.departure_time);

                            return generateTripSection({
                                sectionType: 'Departure',
                                keyValues: [
                                    ['Date', departureDate ?? '-'],
                                    ['Address', props.departureWaypoint.address ?? departure.address],
                                ],
                            });
                        case SectionType.Arrival:
                            const arrival = section.info as ArrivalSection;
                            const arrivalDate = convertStringToDateFormat(arrival.estimated_arrival_time);
                            return generateTripSection({
                                sectionType: 'Arrival',
                                keyValues: [
                                    ['Date', arrivalDate ?? '-'],
                                    ['Address', props.arrivalWaypoint.address ?? arrival.address],
                                ],
                            });

                        case SectionType.Driving:
                            const driving = section.info as DrivingSection;
                            const drivingDate = convertStringToDateFormat(driving.estimated_departure);
                            const distance = convertMetersIntoKilometers(driving.distance);
                            return generateTripSection({
                                sectionType: 'Driving',
                                keyValues: [
                                    ['Date', drivingDate ?? '-'],
                                    ['Distance', `${distance} ${Unit.km}`],
                                ],
                            });

                        case SectionType.AdditionalStop:
                            const stop = section.info as AdditionalStopSection;
                            const stopDate = convertStringToDateFormat(stop.estimated_arrival_time);
                            const duration = convertSecondsIntoHHmm(stop.duration);
                            return generateTripSection({
                                sectionType: 'Additional Stop',
                                keyValues: [
                                    ['Duration', duration ?? '-'],
                                    ['Date', stopDate ?? '-'],
                                    ['Address', stop.address],
                                ],
                            });
                        case SectionType.ChargingStation:
                            const station = section.info as ChargingStationSection;
                            const stationDuration = convertSecondsIntoHHmm(station.duration);
                            const stationDate = convertStringToDateFormat(station.estimated_arrival_time);
                            const operator = station.charging_station.operator.name;
                            return generateTripSection({
                                sectionType: 'Additional Stop',
                                keyValues: [
                                    ['Duration', stationDuration ?? '-'],
                                    ['Date', stationDate ?? '-'],
                                    ['Operator', operator ?? '-'],
                                    ['Address', station.charging_station.address ?? '-'],
                                ],
                            });
                    }
                })}
                <View style={styles.pageCreation}>
                    <Text
                        style={styles.pageCreation}
                        render={() => `Created at: ${props.tourPlanningDate} ${props.tourPlanningTime}`}
                        fixed
                    />
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );
};

interface RouteDownloadPageProps {
    assetName: string;
    assetType: string;
    assetEngine: boolean;
    assetProfile: string;
    assetEmissionClass: string;
    assetHazardClass: string;
    assetCargo: string;
    assetBatteryCapacity: string;
    assetTunnelCategory: string;
    tourPlanningDate: string;
    tourPlanningTime: string;
    tourDepartureDateTime: string;
    tourDistance: string;
    tourDuration: string;
    routeConstraints: RouteConstraints;
    tripSections?: RouteSection[];
    departureWaypoint: Waypoint;
    arrivalWaypoint: Waypoint;
}
