import { lazy } from 'react';
import { createBrowserRouter, createHashRouter, createRoutesFromElements, Route, RouteObject } from 'react-router-dom';
import { DEFAULT_ROUTE, STOPS_ROUTE, SUMMARY_ROUTE } from '../common/constants';
import { FallbackError } from '../components/FallbackError/FallbackError';
import { SuspendedWithSpinner } from '../components/SuspendedWithSpinner/SuspendedWithSpinner';
import { AppLayout } from '../containers/AppLayout/AppLayout';
import { RouteStopoversPage } from '../pages/RouteStopoversPage/RouteStopoversPage';
import { RouteSummaryPage } from '../pages/RouteSummaryPage/RouteSummaryPage';

const RouteSettingsPageLazy = lazy(() =>
    import('../pages/RouteSettingsPage/RouteSettingsPage').then(({ RouteSettingsPage }) => ({
        default: RouteSettingsPage,
    }))
);

export const routes = [DEFAULT_ROUTE, STOPS_ROUTE, SUMMARY_ROUTE];

export const routeObjects: RouteObject[] = createRoutesFromElements(
    <Route path="/" element={<AppLayout />} errorElement={<FallbackError />}>
        <Route
            path={SUMMARY_ROUTE}
            element={
                <SuspendedWithSpinner>
                    <RouteSummaryPage />
                </SuspendedWithSpinner>
            }
        />
        <Route
            path={STOPS_ROUTE}
            element={
                <SuspendedWithSpinner>
                    <RouteStopoversPage />
                </SuspendedWithSpinner>
            }
        />
        <Route
            index
            path={DEFAULT_ROUTE}
            element={
                <SuspendedWithSpinner>
                    <RouteSettingsPageLazy />
                </SuspendedWithSpinner>
            }
        />
    </Route>
);

export const router = createBrowserRouter(routeObjects);
