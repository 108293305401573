import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: {
        display: 'flex',
        backgroundColor: '#E4E4E4',
        padding: 40,
    },
    pageCreation: {
        position: 'absolute',
        fontSize: 10,
        bottom: 20,
        left: 20,
        right: 0,
        textAlign: 'left',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 20,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'grey',
    },
    companyLogo: {
        position: 'absolute',
        top: 20,
        left: 0,
        right: 20,
        width: 24,
        height: 24,
        textAlign: 'right',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    subTitle: {
        marginTop: 15,
        marginBottom: 15,
        fontSize: 16,
    },
    body: {
        top: 44,
        flexGrow: 1,
        fontSize: 12,
        color: 'darkGrey',
    },
    section: {
        marginTop: 10,
    },
    sectionItem: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        fontSize: 12,
    },
    sectionColumn: {
        flexBasis: '50%',
    },
});
