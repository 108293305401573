import { isEmpty, isEqual, isUndefined, size } from 'lodash';
import { API } from '../../common/constants';
import { AssetId } from '../../common/types';
import { config } from '../../configuration/config';
import { fetchData } from '../../configuration/setup/fetch';
import { trace } from '../../configuration/setup/trace';
import { selectCharging } from '../../store/charging/chargingSelectors';
import { selectHereSettings } from '../../store/data/dataSelectors';
import { selectRoute } from '../../store/route/routeSelectors';
import {
    fetchRoutesTriggered,
    fetchRouteSuggestionsFailed,
    suggestedRoutesChanged,
} from '../../store/route/routeSlice';
import { selectAreWaypointsInvalid, selectOrderedWaypoints } from '../../store/search/searchSelectors';
import { selectAccessToken } from '../../store/tokenHandling/tokenHandlingSelectors';
import { selectVehicleProfile } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { changeIsProfileSubmited } from '../../store/vehicleProfile/vehicleProfileSlice';
import { propagateBoundingBox, propagateRoute } from '../livemonitorCommunication/propagateRoutesToParent';
import { createRouteBody } from './createRouteParameters';
import { addDrivingCoordinates } from './mappers/mapRoutes';

// eslint-disable-next-line max-len
const ROUTE_URL = `${config.backend.BACKEND_SERVICE}/${API.serviceName}/${API.version.latest}${API.endpoints.route}`;

export const fetchRoutes = async (dispatch: Function, getState: Function) => {
    try {
        const areWaypointValid = selectAreWaypointsInvalid(getState());
        const orderedWaypointsWithCoordinates = selectOrderedWaypoints(getState());

        if (size(orderedWaypointsWithCoordinates) < 2 || areWaypointValid) {
            return;
        }

        const apiKey = selectHereSettings(getState())?.apikey;
        if (apiKey === undefined) {
            throw new Error('could not resolve Here API key');
        }

        const assetLocked = selectVehicleProfile(getState()).assetLocked?.asset_id as AssetId;
        const vehicleProfile = selectVehicleProfile(getState());
        const routeConfig = selectRoute(getState());
        const chargingConfig = selectCharging(getState());

        const parameters = {
            assetId: assetLocked,
            waypoints: orderedWaypointsWithCoordinates,
            vehicleConfig: vehicleProfile,
            routeConfig,
            chargingConfig,
        };

        dispatch(fetchRoutesTriggered());

        const requestBody = createRouteBody(parameters);

        const response = await fetchData(ROUTE_URL, selectAccessToken(getState()), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (isEmpty(response.routes)) {
            throw new Error('No route alternatives');
        }

        const withDrivingCoordinates = addDrivingCoordinates(response);

        dispatch(suggestedRoutesChanged(withDrivingCoordinates));
        dispatch(propagateRoute);
        dispatch(propagateBoundingBox);
        dispatch(changeIsProfileSubmited(true));
    } catch (error: any) {
        dispatch(fetchRouteSuggestionsFailed(error?.message));
        trace.error('could not fetch route', error);
        throw error;
    }
};
