import isEmpty from 'lodash/fp/isEmpty';
import { createSelector } from 'reselect';
import { RootState } from '../../configuration/setup/store';
import { Waypoint } from './searchSlice';

export const selectSearch = (state: RootState) => state.search;
export const selectWaypoints = createSelector(selectSearch, (state) => state.waypoints);
export const selectWaypointsOrder = createSelector(selectSearch, (state) => state.waypointsOrder);
export const selectSuggestedAddresses = createSelector(selectSearch, (state) => state.suggestedAddresses);
export const selectWaypointOnFocus = createSelector(selectSearch, (state) => state.waypointOnFocus);
export const selectSuggestedAddressesError = createSelector(
    selectSearch,
    (state) => state.fetchSuggestedAddressesError
);
export const selectAreWaypointsInvalid = createSelector(selectWaypoints, (waypoints: Waypoint[]) => {
    const validWaypoints = waypoints.filter((waypoint) => !isEmpty(waypoint.address) && !isEmpty(waypoint.coordinates));
    return validWaypoints.length < 2;
});
export const selectOrderedWaypoints = createSelector(
    selectWaypoints,
    selectWaypointsOrder,
    (waypoints: Waypoint[], order: number[]) =>
        order
            .map((waypointId) => waypoints.find((point) => point.id === waypointId))
            .filter((id) => id !== undefined) as Waypoint[]
);
