import { isNil, uniqueId } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Unit } from '../../../common/constants';
import { convertMetersIntoKilometers, convertSecondsIntoHHmm, convertStringToDateFormat } from '../../../common/utils';
import { DrivingSection } from '../../../store/facade/facadeApi';
import { CurrentStatus } from '../SectionsContainer';

type DrivingPanelProps = {
    driving: DrivingSection;
    isFirstDriving: boolean;
} & CurrentStatus;

export const DrivingPanel = ({ driving, isFirstDriving }: DrivingPanelProps) => {
    const date = convertStringToDateFormat(driving.estimated_departure);

    return (
        <li key={uniqueId()} className="timeline-element" data-testid="timeline-element">
            <div className="timeline-date margin-right-10">
                <div className="border border-width-3 border-color-white">
                    <span className="badge badge-lg badge-muted padding-5">
                        <span className="rioglyph rioglyph-status-driving" />
                    </span>
                </div>
            </div>
            <div className="timeline-content margin-bottom-15">
                <div className="text-medium text-size-16 text-color-darker margin-top-5 margin-bottom-5">
                    <FormattedMessage id="intl-msg:glossary.activity.driving" />
                </div>
                <div className="display-flex flex-column gap-2">
                    <div>
                        <span className="rioglyph rioglyph-time text-color-gray margin-right-5" />
                        {!isNil(driving.estimated_duration) ? (
                            <span>{convertSecondsIntoHHmm(driving.estimated_duration)}</span>
                        ) : (
                            '-'
                        )}
                    </div>

                    {!isFirstDriving && (
                        <div>
                            <span className="rioglyph rioglyph-calendar text-color-gray margin-right-5" />
                            <span>{date}</span>
                        </div>
                    )}

                    <div>
                        <span className="rioglyph rioglyph-route text-color-gray margin-right-5" />
                        {!isNil(driving.distance) ? (
                            <span>{`${convertMetersIntoKilometers(driving.distance)} ${Unit.km}`}</span>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
            </div>
        </li>
    );
};
