import { usePDF } from '@react-pdf/renderer';
import { first, last } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
    convertMetersIntoKilometers,
    convertSecondsIntoHHmm,
    getIntlFormatDate,
    getIntlFormaTime,
} from '../../common/utils';
import { useAppSelector } from '../../configuration/setup/hooks';
import { trace } from '../../configuration/setup/trace';
import { resolveAddress } from '../../features/fetchData/resolveAddress';
import { RouteDownloadPage } from '../../pages/RouteDownloadPage/RouteDownloadPage';
import {
    useGetVehicleProfileListQuery,
    useLazyGetVehicleProfileDetailsQuery,
    VehicleProfileListItem,
} from '../../store/facade/facadeApi';
import { selectLocale } from '../../store/lang/langSelectors';
import {
    selectRouteConstraints,
    selectRouteDeparture,
    selectSelectedRoute,
    selectSuggestedRoutes,
} from '../../store/route/routeSelectors';
import { selectOrderedWaypoints } from '../../store/search/searchSelectors';
import { Waypoint, WaypointType } from '../../store/search/searchSlice';
import {
    selectBatteryCapacity,
    selectCargoWeightField,
    selectEmissionClassification,
    selectHazardClassification,
    selectProfileType,
    selectTruckTypeField,
    selectTunnelCategory,
    selectVehicleProfile,
} from '../../store/vehicleProfile/vehicleProfileSelectors';

export const DownloadPDF = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isFirstWpVehicleLocation, setIsFirstWpVehicleLocation] = useState<boolean>();
    const [vehicleAddress, setVehicleAddress] = useState<string>();

    const localeLang = useAppSelector(selectLocale);
    const assetLocked = useAppSelector(selectVehicleProfile).assetLocked;
    const isBev = assetLocked?.details?.details_bev;
    const vehicleProfileId = useAppSelector(selectProfileType);
    const vehicleTruckType = useAppSelector(selectTruckTypeField)?.value;
    const vehicleCargoWeight = useAppSelector(selectCargoWeightField)?.value?.toString();
    const vehicleBatteryCapacity = useAppSelector(selectBatteryCapacity)?.toString();
    const vehicleEmissionClassification = useAppSelector(selectEmissionClassification);
    const vehicleHazardClassification = useAppSelector(selectHazardClassification);
    const vehicleTunnelCategory = useAppSelector(selectTunnelCategory)?.toString();
    const tourPlanningDate = getIntlFormatDate(localeLang, new Date());
    const tourPlanningTime = getIntlFormaTime(localeLang, new Date());
    const routeDeparture = useAppSelector(selectRouteDeparture);
    const tourDepartureDate = routeDeparture.departureDate;
    const tourDepartureTime = routeDeparture.departureTime;
    const trip = useAppSelector(selectSuggestedRoutes);
    const selectedRoute = useAppSelector(selectSelectedRoute);
    const tripRoutes = trip?.routes[selectedRoute!];
    const tripSecctions = tripRoutes?.sections;
    const tripSummary = tripRoutes?.summary;
    const tourDistance = convertMetersIntoKilometers(tripSummary?.distance)?.toString();
    const tourDuration = convertSecondsIntoHHmm(tripSummary?.duration);
    const routeConstraints = useAppSelector(selectRouteConstraints);
    const waypoints = useAppSelector(selectOrderedWaypoints);
    let departureWaypoint = first(waypoints);
    const arrivalWaypoint = last(waypoints);

    const [vehicleProfileName, setVehicleProfileName] = useState<string>();
    const { data: profilesList } = useGetVehicleProfileListQuery();
    const [fetchDetails, { data: currentDetails }] = useLazyGetVehicleProfileDetailsQuery();

    const getVehicleLocation = async () => {
        try {
            const eventObj = {
                type: 'EVENT_SMART_ROUTING',
                payload: {
                    coordinates: departureWaypoint?.coordinates,
                },
            };

            const resolvedAddress = await dispatch(resolveAddress(eventObj));
            setVehicleAddress(resolvedAddress);
        } catch (error) {
            trace.error('Could not resolve vehicle address: ', error);
        }
    };

    useEffect(() => {
        if (
            departureWaypoint &&
            (departureWaypoint.type === WaypointType.VEHICLE_LOCATION ||
                departureWaypoint.address === intl.formatMessage({ id: 'intl-msg:smartRoute.tour.useVehicleLocation' }))
        ) {
            setIsFirstWpVehicleLocation(true);
            getVehicleLocation();
        } else {
            setIsFirstWpVehicleLocation(false);
        }
    });

    useEffect(() => {
        if (isFirstWpVehicleLocation) {
            departureWaypoint = {
                ...departureWaypoint,
                address: vehicleAddress,
            } as Waypoint;
        }
    }, [vehicleAddress]);

    useEffect(() => {
        if (isBev && profilesList) {
            const profileIndex = profilesList.findIndex(
                (profile: VehicleProfileListItem) => profile.id === vehicleProfileId
            );
            const profileId = profilesList[profileIndex].id;
            if (profileId) {
                fetchDetails({ id: profileId });
            }
        }
    }, [profilesList]);

    useEffect(() => {
        if (isBev && currentDetails) {
            setVehicleProfileName(
                intl.formatMessage({ id: `intl-msg:smartRoute.vehicle.profile.${currentDetails.profileName}` })
            );
        } else {
            setVehicleProfileName(vehicleTruckType);
        }
    }, [currentDetails]);

    const document = () => (
        <RouteDownloadPage
            assetName={assetLocked?.name ?? '-'}
            assetType={assetLocked?.vehicleType ?? '-'}
            assetEngine={isBev!}
            assetProfile={vehicleProfileName ?? '-'}
            assetEmissionClass={vehicleEmissionClassification ?? '-'}
            assetHazardClass={vehicleHazardClassification ?? '-'}
            assetCargo={vehicleCargoWeight ?? '-'}
            assetBatteryCapacity={vehicleBatteryCapacity ?? '-'}
            assetTunnelCategory={vehicleTunnelCategory ?? '-'}
            tourPlanningDate={tourPlanningDate}
            tourPlanningTime={tourPlanningTime}
            tourDepartureDateTime={`${tourDepartureDate} ${tourDepartureTime}`}
            tourDistance={tourDistance ?? '-'}
            tourDuration={tourDuration ?? '-'}
            routeConstraints={routeConstraints}
            tripSections={tripSecctions}
            departureWaypoint={departureWaypoint}
            arrivalWaypoint={arrivalWaypoint}
        />
    );

    const [instance, updateInstance] = usePDF({ document: document() });

    useEffect(() => {
        updateInstance(document());
    }, [trip, vehicleProfileName, vehicleAddress]);

    return (
        <a
            disabled={instance.loading || instance.error}
            className="btn btn-primary btn-outline btn-component"
            href={instance.url}
            download="trip_export.pdf"
            onClick={() => trace.info('User downloaded the planed trip.')}
        >
            <span className="rioglyph rioglyph-download" aria-hidden="true" />
            Export trip
        </a>
    );
};
