import { HttpResponse, http } from 'msw';
import { API, BASE_URL } from '../../../src/common/constants';

const URL = `${BASE_URL}/${API.endpoints.assetSubscription}/:assetId`;

export default http.get(URL, ({ params }) => {
    const assetId = params.assetId as string;
    const subscribed = isAssetSubscribed(assetId);
    return HttpResponse.json({ status: subscribed ? 'ACTIVE' : 'INACTIVE' });
});

const isAssetSubscribed = (assetId: string) => {
    // Asset 04
    if (assetId === '040191ac-d06d-4567-b13a-7b7fd85d9739') {
        return false;
    } else {
        return true;
    }
};

export const activeSubscriptionResponse = http.get(URL, () => {
    return HttpResponse.json({ status: 'ACTIVE' });
});

export const inactiveSubscriptionResponse = http.get(URL, () => {
    return HttpResponse.json({ status: 'INACTIVE' });
});

export const errorSubscriptionResponse = http.get(URL, () => {
    return HttpResponse.json({ errorMessage: 'Mock Subscription response error' }, { status: 400 });
});
