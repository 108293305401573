import { AssetId } from '../../common/types';
import { coordsToCoordinates, getMergeDateTime, mapUnits } from '../../common/utils';
import { ChargingState } from '../../store/charging/chargingSlice';
import { RoutingOptions, VehicleType2 } from '../../store/facade/facadeApi';
import { RouteState } from '../../store/route/routeSlice';
import { WaypointType } from '../../store/search/searchSlice';
import { VehicleProfile } from '../../store/vehicleProfile/vehicleProfileSlice';
import { EmissionClass, HazardClass } from '../../store/vehicleProfile/vehicleProfileTypes';
import { WaypointWithCoordinates } from './fetchWaypointCoordinates';

interface WaypointParameters {
    assetId: AssetId | null;
    waypoints: WaypointWithCoordinates[];
    vehicleConfig: VehicleProfile;
    routeConfig: RouteState;
    chargingConfig: ChargingState;
}

const mapType = (type: WaypointType) => {
    const indexOfS = Object.values(WaypointType).indexOf(type);
    const key = Object.keys(WaypointType)[indexOfS];
    return key;
};

const buildWaypoints = (waypoints?: WaypointWithCoordinates[]) =>
    waypoints?.map(({ id, station_id, address, coordinates, type }) => {
        return {
            ...(station_id && { id: station_id }),
            type: mapType(type),
            location: coordsToCoordinates(coordinates),
        };
    });

const buildChargingOptions = (chargingConfig: ChargingState) => {
    const plugType = [];
    if (chargingConfig.chargingOptions.plugType.css) {
        plugType.push('CCS');
    }
    if (chargingConfig.chargingOptions.plugType.mcs) {
        plugType.push('MCS');
    }

    const chargePower = [];
    if (chargingConfig.chargingPower.lowPower) {
        chargePower.push('LOW');
    }
    if (chargingConfig.chargingPower.lowPower) {
        chargePower.push('MEDIUM');
    }
    if (chargingConfig.chargingPower.highPower) {
        chargePower.push('HIGH');
    }

    const chargeGrading = [];
    if (chargingConfig.chargingOptions.grading.truckReady) {
        chargeGrading.push('TRUCK_READY');
    }
    if (chargingConfig.chargingOptions.grading.limited) {
        chargeGrading.push('LIMITED');
    }
    if (chargingConfig.chargingOptions.grading.unspecified) {
        chargeGrading.push('UNSPECIFIED');
    }

    const chargingOptions = {
        min_soc_percentage: chargingConfig.stateOfCharge.minSoC,
        max_soc_percentage: chargingConfig.stateOfCharge.maxSoC,
        soc_at_destination: chargingConfig.stateOfCharge.destinationSoC,
        soc_at_origin: chargingConfig.stateOfCharge.originSoC,
        vehicle_plug_type: plugType,
        charging_power: chargePower,
        charging_station_grading: chargeGrading,
        charging_station_operator: chargingConfig.chargingOptions.operator,
        payment_options: chargingConfig.chargingOptions.payment,
    };
    return chargingOptions;
};

const buildRoutingOptions = (routeConfig: RouteState) => {
    let routeDepartureDateTime: string;

    if (routeConfig.departure.departureDate) {
        routeDepartureDateTime = getMergeDateTime(
            routeConfig.departure.departureDate,
            routeConfig.departure.departureTime
        );
    } else {
        routeDepartureDateTime = new Date(routeConfig.departure.departureTime).toISOString();
    }

    const routingOptions = {
        departure_time: routeDepartureDateTime,
        cost_per_km: routeConfig.vehicleCosts.value,
        avoid_highways: routeConfig.constraints.isAvoidAccessHighway,
        avoid_toll_roads: routeConfig.constraints.isAvoidTollRoads,
        avoid_ferry: routeConfig.constraints.isAvoidFerry,
        avoid_tunnel: routeConfig.constraints.isAvoidTunnel,
        avoid_route_alternatives: routeConfig.constraints.isAvoidRouteAlternatives,
        currency: routeConfig.vehicleCosts.currency,
        cost_per_kwh: routeConfig.energyCosts.value,
    } as RoutingOptions;
    return routingOptions;
};

const buildVehicleOptions = (vehicleConfig: VehicleProfile) => {
    // TODO remove comments and add those fields when new API version is released
    const vehicleOptions = {
        gross_weight: mapUnits(vehicleConfig.totalWeight?.value, (it) => it * 1),
        height: mapUnits(vehicleConfig.height?.value, (it) => it * 100),
        width: mapUnits(vehicleConfig.width?.value, (it) => it * 100),
        length: mapUnits(vehicleConfig.length?.value, (it) => it * 100),
        vehicle_axles_count: vehicleConfig.vehicleAxles?.value ? vehicleConfig.vehicleAxles?.value : 1,
        trailer_axles_count: vehicleConfig.trailerAxles?.value,
        trailer_count: vehicleConfig.numberAttachedTrailers,
        weight_per_axle: vehicleConfig.weightPerAxle.value,
        hazard_classification:
            vehicleConfig.hazardClassification?.value === HazardClass.NONE
                ? null
                : vehicleConfig.hazardClassification?.value,
        emission_classification:
            vehicleConfig.emissionClassification?.value === EmissionClass.NONE
                ? null
                : vehicleConfig.emissionClassification?.value,
        vehicle_type: vehicleConfig.truckType.value ?? VehicleType2.StraightTruck,

        profile_id: vehicleConfig.profileType.value, // new API
        vehicle_payload_weight: vehicleConfig.payloadWeight.value, // new API

        // from slice and still to define
        // weight_per_axle_group: vehicleConfig.weightPerAxleGroup.value, //new API (does not exist)
        // average_outdoor_temperature: vehicleConfig.averageOutdoorTemperature.value,
        // battery_capacity: vehicleConfig.batteryCapacityValue, //new API kWh (does not exist)
        // tunnelCat null when all
        // tunnel_category: vehicleConfig.tunnelCategory.value, //new API (does not exist)
    };
    return vehicleOptions;
};

export const createRouteBody = (parameters: WaypointParameters) => {
    const waypointsSliced = parameters.waypoints.slice(1, -1);

    return {
        asset_id: parameters.assetId,
        origin: coordsToCoordinates(parameters.waypoints.at(0)?.coordinates!),
        destination: coordsToCoordinates(parameters.waypoints.at(-1)?.coordinates!),
        waypoints: buildWaypoints(waypointsSliced),
        vehicle_options: buildVehicleOptions(parameters.vehicleConfig),
        routing_options: buildRoutingOptions(parameters.routeConfig),
        charging_options: buildChargingOptions(parameters.chargingConfig),
    };
};
