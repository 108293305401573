import { isNil } from 'lodash/fp';
import { store } from '../../../configuration/setup/store';
import { clearRouteAndMarkers } from '../../../features/livemonitorCommunication/propagateRoutesToParent';
import { updateAssetId } from '../widgetSlice';

export const SELECT_ASSET_EVENT = 'EVENT_SELECT_ASSET';

type SelectAssetType = {
    id: string;
};

export const selectAssetHandler = (data: SelectAssetType) => {
    store.dispatch(updateAssetId(data.id));
    if (!isNil(data.id)) {
        clearRouteAndMarkers();
    }
};
