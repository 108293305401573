import { DeepPartial } from '@reduxjs/toolkit';
import { OperatorListResponse } from '../../../src/store/facade/facadeApi';

export const operatorsListMock = (): DeepPartial<OperatorListResponse> => ({
    items: [
        {
            id: '55d80e6d-2c52-4c0f-ad8c-3a80c24069d2',
            name: 'Allego België BV',
        },
        {
            id: '2b8162c5-c5ce-4c04-a072-053542257ec4',
            name: 'Allego BV',
        },
        {
            id: '291c935d-7392-4eae-babb-7b22d6034e92',
            name: 'Allego Charging Ltd',
        },
        {
            id: 'b6ccfcdc-4882-42c6-994c-d4b48621d31f',
            name: 'Allego Denmark Aps',
        },
        {
            id: 'a9883436-a3a5-47a6-87df-f282b4d8890a',
            name: 'Allego France SAS',
        },
        {
            id: 'cc9e5b9a-f161-4906-aead-d26e7e77a34a',
            name: 'Allego GmbH',
        },
        {
            id: '381de288-efae-43c6-b1d4-22fb5572597e',
            name: 'Allego Sweden AB',
        },
        {
            id: '1809a4cd-ccf0-46ff-bd38-ce9461d1a5fc',
            name: 'AMAG',
        },
        {
            id: '9ba6b178-36a4-4f6d-8dda-b30417a2964b',
            name: 'AMAG Import AG',
        },
        {
            id: 'b2b51f78-099e-40ed-81e9-a7e69c031450',
            name: 'Aral Luxembourg S.A',
        },
        {
            id: '6b508ec9-759a-4506-b433-f08d80835192',
            name: 'avency GmbH',
        },
        {
            id: '2ad051ac-381e-4f33-9e06-a22ee729919b',
            name: 'AVIA Plus GmbH & Co. KG',
        },
        {
            id: 'bdeecff7-1f3a-4a1f-91ae-337b58b00950',
            name: 'AVP Autoland GmbH&Co.KG',
        },
        {
            id: 'b434d51a-ad66-4835-946f-197f3e0da78f',
            name: 'BayWa Mobility Solutions GmbH',
        },
        {
            id: 'a71feecf-1768-460f-ac21-05d45dd99484',
            name: 'BELGIAN SHELL. N.V.',
        },
        {
            id: '997a5ad6-c29f-4b7b-8ee0-e2b48bcc5b2b',
            name: 'BE Solution GmbH',
        },
        {
            id: '123cc5ef-0e84-48e4-a6aa-2cb0f8cd8258',
            name: 'Bosch Charging Solutions GmbH',
        },
        {
            id: '343eb1fb-2135-4bc9-bfaf-47223d91cc48',
            name: 'Bouygues Energies et Services',
        },
        {
            id: '2aefcdfb-b632-420b-85af-c5c07b3e6322',
            name: 'BP Europa SE',
        },
        {
            id: 'f8fcc403-fa29-4924-8394-b5ab520ec7a5',
            name: 'Bump',
        },
        {
            id: '949ae936-4211-4351-aca6-235959b0749c',
            name: 'chargecloud GmbH',
        },
        {
            id: '4e63d2ae-a75c-4a5c-a1d2-f3d788989bb2',
            name: 'ChargeGuru',
        },
        {
            id: 'df032c47-9e25-401a-9114-c9555552141d',
            name: 'ChargePoint Austria GmbH',
        },
        {
            id: '2f94bb03-e272-42e0-b9c9-855b6715c11e',
            name: 'ChargePoint Network (Netherlands) B.V.',
        },
        {
            id: 'dc2e6ae8-cc72-413c-84cd-c4fe1cebbdfe',
            name: 'Chargit B.V.',
        },
        {
            id: 'd4bd2970-27d3-4eb3-bc23-8b414228b6e8',
            name: 'Chargy',
        },
        {
            id: '23390541-d15c-40d5-8050-15c61b480834',
            name: 'Citywatt GmbH',
        },
        {
            id: '481ebb44-0cc6-471a-9413-beaf8823957e',
            name: 'Comfortcharge GmbH',
        },
        {
            id: '190dbdb6-57fc-44e1-ba2a-b90f16068ccd',
            name: 'Compleo Charging Applications GmbH',
        },
        {
            id: 'e8be5b5d-81ae-4c2e-8f85-921424d475af',
            name: 'Compleo Charging Software GmbH',
        },
        {
            id: 'bdcb0b67-a83e-494e-b3e4-21e9e7cb0c9b',
            name: 'Compleo Charging Solutions UK Limited',
        },
        {
            id: 'd0445a40-55b4-44ce-af8b-b0ba9ffff4fb',
            name: 'Cooperatie AVIA Nederland U.A.',
        },
        {
            id: '430530bb-95b3-4a54-871e-8fcb05ef2185',
            name: 'da emobil GmbH & Co KG',
        },
        {
            id: '3909c7a6-3dbd-4240-8d1f-2f3a2481a5f4',
            name: 'Driveco',
        },
        {
            id: '50018cab-7e25-4d98-b038-aad631a92360',
            name: 'EA Energiearchitektur GmbH',
        },
        {
            id: '5ae7d363-7b81-482e-bae0-56dd245846f1',
            name: 'Eaton Industries II GmbH',
        },
        {
            id: '1655e913-611a-4b41-bf2a-a98369bc9902',
            name: 'eCarUp AG',
        },
        {
            id: 'acd7f46a-3af8-4e71-9322-218aa88fb05b',
            name: 'EDEKA Versorgungsgesellschaft mbH',
        },
        {
            id: '0a6d7fc9-7332-4cd8-8e2b-a61c6e9fa973',
            name: 'E-Flux B.V.',
        },
        {
            id: '08ea93fa-1d57-4f99-8c0e-7f7cde991c21',
            name: 'Electra',
        },
        {
            id: '88095ea9-7ca1-4548-9ce9-9e5e4da158fa',
            name: 'Electra SAS',
        },
        {
            id: '4ba87c6f-feaa-4247-a4ad-f1446e519270',
            name: 'Electric 55 Charging',
        },
        {
            id: '049319cd-0a0a-4634-b8d2-66c0e8716fc3',
            name: 'Elocity Sp z o. o.',
        },
        {
            id: 'db445e31-dbb0-40fa-8654-70fdbcf852bb',
            name: 'EnBW mobility+ AG & Co. KG',
        },
        {
            id: '320d777a-1ccb-43ed-8b42-54efd1cd80f4',
            name: 'EnBW Ostwürttemberg DonauRies AG',
        },
        {
            id: '28baedb5-0e32-4715-a329-f7a5842324b9',
            name: 'enercity AG',
        },
        {
            id: '937feb91-f376-413c-aa6a-cfbfd841016a',
            name: 'Energie AG Oberösterreich Vertrieb GmbH',
        },
        {
            id: '00c278e5-6a91-4de1-8cf3-1cb1e1f226ce',
            name: 'Energiedienst Holding AG',
        },
        {
            id: '4f5a9a8e-1006-4806-9952-b73e7945f02f',
            name: 'energielösung GmbH',
        },
        {
            id: '6bb3c9ab-9f26-4a14-9555-d0d2c2ea3fff',
            name: 'E.ON Drive GmbH',
        },
        {
            id: '007fbe64-88a7-4cb9-b7b5-69771ff995f8',
            name: 'E.ON Drive Infrastructure GmbH',
        },
        {
            id: 'f6913d66-9a9b-4d3a-86f3-a37ab5448cda',
            name: 'ESB Group - United Kingdom',
        },
        {
            id: '5a7e5eb1-88e1-4059-bfac-3f919a68fba4',
            name: 'E-Totem SAS',
        },
        {
            id: '5ddc363c-2abd-4b51-a617-ee0b3a040166',
            name: 'EVBox B.V.',
        },
        {
            id: 'edd0c4ac-bf68-4661-b410-419a125b8631',
            name: 'Eviny Elektrifisering AS',
        },
        {
            id: 'c6cb1fb3-488d-4eb5-b022-957c42800e9d',
            name: 'EWE Go GmbH',
        },
        {
            id: '7bc87777-4496-4488-894f-ce5d402d7c84',
            name: 'Ewiva S.r.l.',
        },
        {
            id: '7fe6970e-5793-4aab-9d8f-4fa9bb8d9a7b',
            name: 'Extendas Online Informatica B.V.',
        },
        {
            id: 'c975ad81-96e1-4e66-b64d-a80ae45b6555',
            name: 'Floading Energy Infra B.V.',
        },
        {
            id: 'ec693499-6959-4462-a130-dab622cbaf1d',
            name: 'Freshmile Services SAS',
        },
        {
            id: 'e01b10ed-6cb5-489b-936a-fe30d60fff34',
            name: 'GP JOULE Connect GmbH',
        },
        {
            id: '47f08194-6b54-4dd9-8778-cb1240f5c706',
            name: 'GreenFlux Assets B.V.',
        },
        {
            id: '50963dec-41ed-4c14-8215-071b1d43e978',
            name: 'Green Motion SA',
        },
        {
            id: 'f6bd85d5-31bb-4a28-8627-ec3689e862a8',
            name: 'Grid & Co. GmbH',
        },
        {
            id: 'd1f25ef0-34ed-4d7d-a21f-87c9cf802101',
            name: 'illwerke vkw AG',
        },
        {
            id: '5cb8b134-3b42-4a86-8fa1-5f6594bdda72',
            name: 'illwerke vkw Deutschland GmbH',
        },
        {
            id: '0212b8f5-5ae6-4e1b-b89f-55a2400f29c9',
            name: 'InCharge AB',
        },
        {
            id: 'c9f4d880-e461-45e0-a502-c666c1d4d163',
            name: 'Inselwerke eG - Geschäftsstelle Eberswalde',
        },
        {
            id: '9cf0ecc9-8996-4da3-b88f-769175a414eb',
            name: 'IONITY GmbH',
        },
        {
            id: '191a9b39-628c-45ad-acce-5e86cf37935e',
            name: 'IONITY GmbH ',
        },
        {
            id: 'a513d69d-a98c-4870-aa3a-c11a0b3d75e3',
            name: 'Izivia',
        },
        {
            id: '24ac4911-bff5-466c-9c11-156a6d591efe',
            name: 'Jolt Energy GmbH',
        },
        {
            id: 'e4ba990b-f109-4e92-9b94-2f424c9975e1',
            name: 'KALLISTA BDR',
        },
        {
            id: '48fbbf6f-840f-4356-8b01-9b5369ccb19d',
            name: 'Kaufland Dienstleistung GmbH & Co. KG',
        },
        {
            id: 'c39aea15-6388-4af4-b298-dc44f96b8c93',
            name: 'K-Auto Oy',
        },
        {
            id: '4e7c89b9-0a1e-424c-b031-9142d7e1187e',
            name: 'KELAG-Kärntner Elektrizitäts AG',
        },
        {
            id: '58e2a0f0-6191-42a3-8e77-067f51a084f2',
            name: 'Kople AS',
        },
        {
            id: '828eb9fe-3a73-4e2a-9f69-af5bc811059d',
            name: 'Kuwait Petroleum (Belgium) N.V.',
        },
        {
            id: '3f9a19aa-d32b-405a-977c-9dbe0333e825',
            name: 'Kuwait Petroleum (Nederland) B.V.',
        },
        {
            id: '838009b6-ca76-4921-af72-4688258c8e22',
            name: 'Ladegrün! eG',
        },
        {
            id: '29e9b3c9-9886-4202-85b9-721045476633',
            name: 'Leikermoser Energiehandel GmbH',
        },
        {
            id: '0b52c2c5-c51a-4d06-8f5b-55621cdb9859',
            name: 'LichtBlick eMobility GmbH',
        },
        {
            id: 'd0da1819-5806-44f0-957a-0937f0110bb8',
            name: 'Lidl Dienstleistung GmbH & Co. KG',
        },
        {
            id: 'ccf1b047-a767-45d2-8bfb-7417fd3a5aad',
            name: 'Lidl France SNC',
        },
        {
            id: '0d486949-87d1-419f-8feb-566f1bcc9562',
            name: 'Liikennevirta Oy',
        },
        {
            id: 'f83506ff-5804-4dc0-90f4-5c9adf007dad',
            name: 'LINZ STROM GAS WAERME GmbH',
        },
        {
            id: 'b4cb3677-0d27-4cbe-893d-2a2afe0745f9',
            name: 'Losch Import S.à.r.l',
        },
        {
            id: '922672d8-2e8c-43cb-9347-f92b4eb6f70e',
            name: 'MaxSolar GmbH ',
        },
        {
            id: '8d670751-5864-446a-be4c-8025422d2447',
            name: 'MEA Energies (e-ChargeNow)',
        },
        {
            id: '47a54cf0-1161-4490-98d8-dc3176e22b2a',
            name: 'Mer Germany GmbH',
        },
        {
            id: '9f246cc5-e420-4ccd-a342-e639bf61251a',
            name: 'Mer Sweden AB',
        },
        {
            id: '751e04da-2ad4-4cfb-8457-e1f2a348af4c',
            name: 'Métropolis',
        },
        {
            id: 'f22a5234-6fb3-43b7-9a74-c9aa95e2d9c1',
            name: 'Monta ApS',
        },
        {
            id: 'cc036389-2111-482b-8ec7-09543b78ef3b',
            name: 'MOON POWER GmbH',
        },
        {
            id: '27c577af-1d51-4fc2-b306-1fe6a1b2e703',
            name: 'MOVE Mobility SA',
        },
        {
            id: 'ab4e4b9f-2c86-49a8-9727-e79aee8bcc02',
            name: 'msu solutions GmbH',
        },
        {
            id: 'a10db791-ed23-4c11-9f25-45f9a3d687e7',
            name: 'NLCharge',
        },
        {
            id: 'e2def52f-22f4-4209-86f1-7fc54b9d9acd',
            name: 'Norlys Charging ApS',
        },
        {
            id: '5c22c9de-ca85-4909-b8fa-f2144d11dab5',
            name: 'NW IECharge Bretagne',
        },
        {
            id: 'f0d0a916-520b-4455-b89e-a99338c905f3',
            name: 'ÖAMTC Verbandsbetriebe GmbH',
        },
        {
            id: 'a371610e-6f67-41e0-8407-b64b0f6c2123',
            name: 'Optimile',
        },
        {
            id: '669de81d-35d6-4dd9-a65e-bd5f4131543e',
            name: 'Optimile société anonyme',
        },
        {
            id: '17565b68-2813-410e-a9e8-392d69dffc10',
            name: 'Osprey Charging Network Ltd',
        },
        {
            id: '19e4d9fc-4f3a-41a0-a30e-a45ac0a58f18',
            name: 'Park & Charge i Sverige AB',
        },
        {
            id: '032b8dbc-fc07-44ca-88ff-b1b1d439428e',
            name: 'Parkstrom GmbH',
        },
        {
            id: '684f1895-d7c8-417e-9fcb-7ceb3c7c89ad',
            name: 'Porsche Sales & Marketplace GmbH',
        },
        {
            id: 'd0af7268-581f-40c2-aa7c-710e03345ffe',
            name: 'Power Dot France SAS',
        },
        {
            id: '83f23926-43ae-4e43-8b3c-d2ad60cc1fb8',
            name: 'Proviridis',
        },
        {
            id: 'e78b3917-3125-4693-a1a7-2dacdbff97b0',
            name: 'Q1 Energie AG',
        },
        {
            id: 'a01cac2c-ad59-4627-85e6-c1711fc8af0b',
            name: 'QoWatt',
        },
        {
            id: '58cfa0ee-c76e-4ebc-8dd2-cef68981fee9',
            name: 'Recharge AS',
        },
        {
            id: '5d350547-4f5c-4433-9d5c-30c3115a239c',
            name: 'Recharge Finland OY',
        },
        {
            id: '1c8d7805-9716-4c6a-8bd3-d41dc506204c',
            name: 'Recharge Sweden AB',
        },
        {
            id: 'c41f0298-ba41-4f39-b09e-516f8de6132b',
            name: 'reev GmbH',
        },
        {
            id: 'b2a6cfb1-87f2-4c61-89e7-6eb821b232ea',
            name: 'Repower AG (Plug ´n Roll)',
        },
        {
            id: '18c97cd0-6be5-444e-9826-6a1760e89668',
            name: 'Réseau E. Leclerc FRLE2',
        },
        {
            id: '10e94d6b-3c39-493f-9039-e4c611d8d989',
            name: 'Reseau Reveo',
        },
        {
            id: '2c5ccfd8-e68b-4ded-9554-d54f4c0caf7b',
            name: 'Road B.V.',
        },
        {
            id: 'e62fffa0-44da-42aa-ba0a-0dfddcc1fca3',
            name: 'Saascharge International GmbH',
        },
        {
            id: '27ba5b7a-083c-4fa8-aec5-65a367b31abb',
            name: 'SachsenEnergie AG',
        },
        {
            id: '2362c1ae-b12a-4b25-8ca2-ac8e2a3bb1da',
            name: 'SalzachSonne GmbH',
        },
        {
            id: 'b438503d-f7cb-4144-aaa2-8651ca821be5',
            name: 'Salzburg AG für Energie, Verkehr und Telekommunikation',
        },
        {
            id: '31e98817-87b1-4ab5-af71-58c0479fe4aa',
            name: 'SAP Labs France SAS',
        },
        {
            id: '6f0686ef-b60e-44b1-86be-edfcbbdd57c7',
            name: 'SGA Automation',
        },
        {
            id: 'c1aa8e63-0601-4a5b-9262-f20daf29f75a',
            name: 'Shell EV Charging Solutions B.V.',
        },
        {
            id: '99a4c4ca-364e-45b6-b61e-9cd2fb4ac8d4',
            name: 'Shell (Switzerland) AG',
        },
        {
            id: 'd52fb315-d852-4f88-afd4-bb2514be1f50',
            name: 'smartlab Innovationsgesellschaft mbH',
        },
        {
            id: 'a735aeec-5a6d-4d56-8be7-a66e7958c167',
            name: 'SMATRICS GmbH & Co KG',
        },
        {
            id: '578f3845-9ac3-4491-ae2d-d881c5d5394c',
            name: 'smopi - Multi Chargepoint Solution GmbH',
        },
        {
            id: 'fd5fba9d-1e71-4eb5-8955-4e7b57c41037',
            name: 'Sperto ApS',
        },
        {
            id: '10cf3c7d-e0fa-457c-b66d-5b329a38d4ec',
            name: 'SPIE CityNetworks (SDEY89)',
        },
        {
            id: '355b2176-d566-4ebe-8a72-a22ea3a87ac3',
            name: 'Spirii ApS',
        },
        {
            id: '6df55ff0-9c49-4039-af19-0511330cd6aa',
            name: 'Stadtwerke Bochum GmbH',
        },
        {
            id: '7a525128-c63d-48bb-a1a8-5233deac5a07',
            name: 'Stadtwerke Elmshorn',
        },
        {
            id: '12628b74-f415-43e4-bf75-eedefb94a333',
            name: 'Stadtwerke Kaarst GmbH',
        },
        {
            id: 'c1f97feb-623f-4278-a4c6-7bfaffbb1f29',
            name: 'Stadtwerke Leipzig GmbH',
        },
        {
            id: '8c261fd7-3acc-4b09-8300-50ca43a2458f',
            name: 'Stadtwerke Lübeck GmbH',
        },
        {
            id: 'd31b3078-8791-42d4-a08d-d99440149b11',
            name: 'Stadtwerke Neustrelitz GmbH',
        },
        {
            id: '37cf672e-d1e6-4563-a47c-d10ea2d8fb5b',
            name: 'Stadtwerke Niesky GmbH',
        },
        {
            id: 'cec8afba-ad3f-4d5d-8a70-08f04a1637bc',
            name: 'Stadtwerke Norderstedt Städtischer Eigenbetrieb',
        },
        {
            id: '91b7930e-f664-4656-8855-b4293e785c79',
            name: 'Stadtwerke Stuttgart GmbH',
        },
        {
            id: 'c48a0151-9ffa-43f5-a1fc-9bbadd07de51',
            name: 'Stadtwerke Weißwasser GmbH ',
        },
        {
            id: '9a8ae368-eeb3-4c13-877a-3f5dc1d676ef',
            name: 'Stromnetz Hamburg GmbH',
        },
        {
            id: 'feedcdd8-b96b-4ed1-bd1f-6abe37e9ab6a',
            name: "Syndicat de l'Énergie de l'Orne (SE61)",
        },
        {
            id: '56cc2616-9b2e-4f35-a086-6e642c52de0b',
            name: 'Syndicat d’Énergie et d’Équipement de la Vendée (SyDEV)',
        },
        {
            id: 'a9067cfe-e363-487e-af1c-5d4b6aa28275',
            name: "Syndicat Départemental d'Énergie Électrique de Gironde",
        },
        {
            id: '66bbc2c9-31ae-4d68-8e86-fe708eb1d6d3',
            name: 'Taubert Consulting GmbH',
        },
        {
            id: 'd4fd05ec-1408-4aee-898b-26718e827591',
            name: "Territoire d'Énergie Lot-et-Garonne",
        },
        {
            id: '14211273-9931-4be9-ba0d-e243f6502a2a',
            name: 'Threeforce B.V.',
        },
        {
            id: 'e971f80f-786e-403f-b588-41518c2a3c45',
            name: 'TIWAG Tiroler Wasserkraft AG',
        },
        {
            id: '9a4ac091-1a64-4c31-b11d-81055cd20862',
            name: 'TotalEnergies Marketing Belgium SA/NV',
        },
        {
            id: '01e32403-b868-490a-b546-34477fda49fa',
            name: 'TotalEnergies Marketing France SAS',
        },
        {
            id: '04f0174b-34ca-4422-abca-85f36a7a5e0c',
            name: 'TotalEnergies Marketing France S.A.S',
        },
        {
            id: '4d8da464-8fd8-41e1-a0ed-6ddb1e51c34f',
            name: 'TotalEnergies MKG Luxembourg SA',
        },
        {
            id: '4d9403a1-b7ad-4d82-a941-49adcc11eedf',
            name: 'Total EV Charge',
        },
        {
            id: '90c582ef-2744-489b-8e17-13374c93cc9f',
            name: 'TOTAL EV CHARGE SERVICES SAS',
        },
        {
            id: 'db2b6130-70f6-4e46-a875-3b470e9d1956',
            name: 'Ultra-Fast Charging Venture Scandinavia ApS',
        },
        {
            id: '0b05d0f5-a5b5-49b0-a0bd-a29ea8551aab',
            name: 'Versorgungsbetriebe Hoyerswerda GmbH',
        },
        {
            id: '0de43715-9074-4d50-b6a7-5d498a2203c2',
            name: 'Volkswagen Group Charging GmbH',
        },
        {
            id: 'afa63847-e903-478b-b8ff-94dcc1e5fca4',
            name: 'Westfalen AG',
        },
        {
            id: 'af20adfc-aada-44a5-872a-f3510bebf600',
            name: 'Westfalen Weser Ladeservice GmbH',
        },
        {
            id: 'ca52b705-3777-44f6-9d25-06c7b8bfe699',
            name: 'Wien Energie GmbH',
        },
        {
            id: '93ec2181-db92-44c1-a4cc-1ca41958ec24',
            name: 'Wirelane GmbH',
        },
        {
            id: '140de309-ef5a-485a-9964-4795dae9e641',
            name: 'EV PLUS SPÓŁKA Z OGRANICZONĄ',
        },
        {
            id: '7f0b66c0-5fa2-4bba-be1b-05f56072f8de',
            name: 'GreenWay Polska Sp. z o.o.',
        },
        {
            id: '6ee2cb05-4bb7-4ead-923a-e35e54ec621b',
            name: 'Scania Sverige AB',
        },
    ],
});
