import StatusBar from '@rio-cloud/rio-uikit/StatusBar';
import { eq, gt, isNil, max, min, round } from 'lodash';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Unit } from '../../common/constants';
import { useAppSelector } from '../../configuration/setup/hooks';
import { selectIsEV } from '../../store/vehicleProfile/vehicleProfileSelectors';

interface TourSummaryPanelBodyProps {
    bodyDepTime?: string;
    bodyDepAddress?: string;
    bodyArrTime?: string;
    bodyArrAddress?: string;
    totalConsumption?: number;
    co2Savings?: number;
    vehicleCost?: number;
    energyCost?: number;
    tollCost?: number;
    totalCost?: number;
    currency?: string;
}

interface HeadlineProps {
    headline?: ReactElement;
    description?: string;
}

const Headline = ({ headline, description }: HeadlineProps) => (
    <div className="display-flex-md align-items-baseline margin-bottom-10">
        <div className="text-normal text-size-12 text-color-dark">{headline}</div>
        <div className="text-medium">{description}</div>
    </div>
);

export const TourSummaryPanelBody = ({
    bodyDepTime,
    bodyDepAddress,
    bodyArrTime,
    bodyArrAddress,
    totalConsumption,
    co2Savings,
    vehicleCost,
    energyCost,
    tollCost,
    totalCost,
    currency,
}: TourSummaryPanelBodyProps) => {
    const isEV = useAppSelector(selectIsEV).value;

    const vehicleCostPct = vehicleCost && totalCost ? Number(round((vehicleCost * 100) / totalCost, 2).toFixed(2)) : 0;
    const energyCostPct = energyCost && totalCost ? Number(round((energyCost * 100) / totalCost, 2).toFixed(2)) : 0;
    const tollCostPct = tollCost && totalCost ? Number(round((tollCost * 100) / totalCost, 2).toFixed(2)) : 0;

    const getProgressBarColor = (value: number): string | undefined => {
        const costsPctList = [vehicleCostPct, energyCostPct, tollCostPct];
        const maxValue = max(costsPctList);
        const minValue = min(costsPctList);

        if (eq(value, maxValue)) {
            return 'bg-coldplay-kashmir';
        } else if (eq(value, minValue)) {
            return 'bg-info';
        } else {
            return 'bg-coldplay-fountain';
        }
    };

    return (
        <div>
            <div data-testid="tour-from">
                <div className="text-normal text-size-12 text-color-dark">From</div>
                <div className="text-medium">{bodyDepTime}</div>
                <div className="text-size-h6">{bodyDepAddress}</div>
            </div>

            <div className="margin-top-20" data-testid="tour-to">
                <div className="text-normal text-size-12 text-color-dark">To</div>
                <div className="text-medium">{bodyArrTime}</div>
                <div className="text-size-h6">{bodyArrAddress}</div>
            </div>

            {(!isNil(totalConsumption) || !isNil(co2Savings)) && (
                <div className="margin-top-25 display-grid grid-cols-2 gap-15" data-testid="tour-details">
                    {isEV && !isNil(totalConsumption) && (
                        <div>
                            <div className="text-normal text-size-12 text-color-dark">
                                <FormattedMessage id="intl-msg:smartRoute.tour.totalConsumption" />
                            </div>
                            <div className="text-medium">
                                {totalConsumption ? `${Math.round(totalConsumption)} ${Unit.KWH}` : '-'}
                            </div>
                        </div>
                    )}
                    {!isNil(co2Savings) && (
                        <div>
                            <div className="text-normal text-size-12 text-color-dark">
                                <FormattedMessage id="intl-msg:smartRoute.tour.co2Savings" />
                            </div>
                            <div className="text-medium">
                                {co2Savings ? `${Math.round(co2Savings)} ${Unit.weight}` : '-'}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {!isNil(totalCost) && gt(totalCost, 0) && (
                <div className="margin-top-15" data-testid="tour-costs">
                    <Headline
                        headline={<FormattedMessage id="intl-msg:smartRoute.tour.costEstimation" />}
                        description={totalCost ? `${totalCost} ${currency}` : '-'}
                    />
                    <StatusBar
                        size="large"
                        progress={[
                            {
                                percentage: vehicleCostPct,
                                color: getProgressBarColor(vehicleCostPct),
                                tooltipPosition: 'bottom',
                                tooltip: (
                                    <span>
                                        <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleCosts" />
                                        {`: ${vehicleCost} ${currency}`}
                                    </span>
                                ),
                            },
                            {
                                percentage: energyCostPct,
                                color: getProgressBarColor(energyCostPct),
                                tooltipPosition: 'bottom',
                                tooltip: (
                                    <span>
                                        <FormattedMessage id="intl-msg:smartRoute.vehicle.energyCosts" />
                                        {`: ${energyCost} ${currency}`}
                                    </span>
                                ),
                            },
                            {
                                percentage: tollCostPct,
                                color: getProgressBarColor(tollCostPct),
                                tooltipPosition: 'bottom',
                                tooltip: (
                                    <span>
                                        <FormattedMessage id="intl-msg:smartRoute.vehicle.tollCosts" />
                                        {`: ${tollCost} ${currency}`}
                                    </span>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
        </div>
    );
};
