export interface FeatureFlag {
    name: string;
    isActive: boolean;
}

export interface ToogleFlag {
    [x: string]: boolean;
}

export const buildFeatureFlagsStruture = (flags: ToogleFlag) => {
    const featureFlags = Object.keys(flags).map((flag) => {
        return { name: flag, isActive: flags[flag] as boolean };
    });
    return featureFlags;
};
