import { flow, map, slice } from 'lodash/fp';
import { MAX_AUTO_SUGGEST_ITEMS, MAX_AUTO_SUGGEST_RADIUS } from '../../common/constants';
import { Coordinates } from '../../common/types';
import { coordsToCoordinates } from '../../common/utils';
import { facadeApi } from '../../store/facade/baseApi';
import { ChargingStation } from '../../store/facade/facadeApi';
import { Address, WaypointType } from '../../store/search/searchSlice';

const mapToAddress = (station: ChargingStation): Address => {
    return {
        title: station.name,
        position: { lat: station.location.latitude, lng: station.location.longitude },
        type: WaypointType.CHARGING_STATION,
        resultType: 'charging-station',
        station_id: station.id,
    };
};

const processStations = flow(slice(0, MAX_AUTO_SUGGEST_ITEMS), map(mapToAddress));

export const fetchChargingStationsNearAddresses = async (processedAddresses: Address[], dispatch: Function) => {
    const stationsAddresses: Coordinates[] = [];
    processedAddresses.forEach((address) => {
        stationsAddresses.push(coordsToCoordinates(address.position));
    });

    const { data } = await dispatch(
        facadeApi.endpoints.getNearestChargingStation.initiate({
            nearestChargingStationRequest: {
                coordinates: stationsAddresses,
                radius: MAX_AUTO_SUGGEST_RADIUS,
                limit: MAX_AUTO_SUGGEST_ITEMS,
            },
        })
    );

    return processStations(data.items);
};
