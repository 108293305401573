import { createAction, createSlice } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { Coords, EventAssetSidebarTab, MapContext } from '../../common/types';
import { Marker, Segment } from '../../features/livemonitorCommunication/propagateRoutesToParent';
import { Waypoint } from '../facade/facadeApi';
import { CenterMapType } from './actions/centerMap';
import { MapChargingStation } from './actions/renderChargingStations';
import { RenderShapeType } from './actions/renderShapes';
import { SelectGeofencesType } from './events/selectGeofences';
import { SelectPoisType } from './events/selectPois';
import { MapContextType } from './events/setMapContext';

export const setBoundingboxType = 'EVENT_SET_BOUNDING_BOX';
export const renderRoute = 'EVENT_RENDER_ROUTE';
export const renderChargingStations = 'EVENT_RENDER_CHARGING_STATIONS';
export const setRoutingStartPointType = 'EVENT_SET_ROUTING_START_POINT';
export const setRoutingEndPointType = 'EVENT_SET_ROUTING_END_POINT';
export const refreshPoisType = 'EVENT_REFRESH_POIS';
export const refreshGeofencesType = 'EVENT_REFRESH_GEOFENCES';
export const sidebarTabSelectionChangedType = 'EVENT_SET_ASSET_SIDEBAR_TAB';

export type BoundingBoxType = {
    bbox: {
        topLeft: Coords;
        bottomRight: Coords;
    };
};

export type renderRouteType = {
    segments: Segment[];
    markers: Marker[];
};

export type RenderChargingStationsType = {
    chargingStations: MapChargingStation[];
    context?: MapContextType;
};

export const widgetActions = {
    widgetLoaded: createAction('EVENT_WIDGET_LOADED'),
    setWidgetId: createAction('EVENT_SET_WIDGET_ID'),
    setVisibleWidgets: createAction('EVENT_SET_VISIBLE_WIDGETS'),
    centerActiveAsset: createAction('EVENT_CENTER_ACTIVE_ASSET'),
    setBoundingBox: createAction<BoundingBoxType>(setBoundingboxType),
    renderRoute: createAction<renderRouteType>(renderRoute),
    renderChargingPoints: createAction<RenderChargingStationsType>(renderChargingStations),
    setRoutingStartPoint: createAction(setRoutingStartPointType),
    setRoutingEndPoint: createAction(setRoutingEndPointType),
    refreshPois: createAction(refreshPoisType),
    refreshGeofences: createAction(refreshGeofencesType),
    centerMap: createAction<CenterMapType>('EVENT_CENTER_MAP'),
    renderStations: createAction<MapChargingStation[] | undefined>('EVENT_RENDER_STATIONS'),
    renderShapes: createAction<RenderShapeType>('EVENT_RENDER_SHAPE'),
    selectAsset: createAction<string | undefined>('EVENT_SELECT_ASSET'),
    sidebarTabSelectionChanged: createAction<EventAssetSidebarTab>(sidebarTabSelectionChangedType),
};

export interface WidgetState {
    selectedAssetId: string | null;
    widgetId?: string;
    isVisible: boolean;
    mapContext: MapContextType;
    selectedPois: SelectPoisType;
    selectedGeofences: SelectGeofencesType;
    selectedRouteStartPoint?: Waypoint;
    selectedRouteEndPoint?: Waypoint;
}

const initialState: WidgetState = {
    selectedAssetId: null,
    isVisible: false,
    mapContext: MapContext.MAP_CONTEXT_DEFAULT,
    selectedPois: [],
    selectedGeofences: [],
    selectedRouteStartPoint: undefined,
    selectedRouteEndPoint: undefined,
};

export const widgetSlice = createSlice({
    name: `${STORE_BASE_PATH}widget`,
    initialState,
    reducers: {
        updateAssetId: (state, action) => {
            state.selectedAssetId = action.payload;
        },
        updateWidgetId: (state, action) => {
            state.widgetId = action.payload;
        },
        updateWidgetVisibility: (state, action) => {
            state.isVisible = action.payload;
        },
        updateMapContext: (state, action) => {
            state.mapContext = action.payload;
        },
        updateSelectedPois: (state, action) => {
            state.selectedPois = action.payload;
        },
        updateSelectedGeofences: (state, action) => {
            state.selectedGeofences = action.payload;
        },
    },
});

export const {
    updateAssetId,
    updateWidgetId,
    updateWidgetVisibility,
    updateMapContext,
    updateSelectedPois,
    updateSelectedGeofences,
} = widgetSlice.actions;

export default widgetSlice.reducer;
