import 'iframe-resizer/js/iframeResizer.contentWindow';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { main } from './configuration';
import { config } from './configuration/config';
import './configuration/setup/datadog';
import { handleLoginRedirect } from './configuration/setup/redirect';
import { store } from './configuration/setup/store';
import { RouteWidgetProvider } from './context/RouteWidgetContext';
import { router } from './routes/Router';
import { worker } from '../test/mocks/lib/serviceMock';

const renderApplication = () => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <Provider store={store}>
            <RouteWidgetProvider>
                <RouterProvider router={router} />
            </RouteWidgetProvider>
        </Provider>
    );
};

if (config.enableMockServer) {
    worker
        .start({
            onUnhandledRequest: 'bypass',
        })
        .then(() => main(renderApplication));
} else {
    main(renderApplication);
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
}
