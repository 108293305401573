import { uniqueId } from 'lodash';
import { Coords, GeofenceType, MapContext } from '../../../common/types';
import { MapContextType } from '../events/setMapContext';
import { sendMessage } from '../widgetServices';
import { widgetActions } from '../widgetSlice';

const DEFAULT_STROKE_COLOR = 'rgba(90, 72, 118, 1)';
const DEFAULT_FILL_COLOR = 'rgba(90, 72, 118, 0.3)';

export class Shape {
    constructor(
        public id: string,
        public type: GeofenceType,
        public radius?: number,
        public precision?: number,
        public strokeColor?: string,
        public fillColor?: string,
        public center?: Coords,
        public points?: Coords[]
    ) {}
}

export type RenderShapeType = {
    context?: MapContextType;
    shapes: Shape[];
};

export class ShapeBuilder {
    private id: string;
    private type!: GeofenceType;
    private radius?: number;
    private precision?: number;
    private strokeColor?: string;
    private fillColor?: string;
    private center?: Coords;
    private points?: Coords[];

    constructor() {
        this.id = `SRP_shape_${uniqueId()}`;
        this.strokeColor = DEFAULT_STROKE_COLOR;
        this.fillColor = DEFAULT_FILL_COLOR;
    }

    withId(id: string) {
        this.id = id;
        return this;
    }

    withType(type: GeofenceType) {
        this.type = type;
        return this;
    }

    withRadius(radius: number) {
        this.radius = radius;
        this.type = GeofenceType.Circle;
        return this;
    }

    withPrecision(precision: number) {
        this.precision = precision;
        return this;
    }

    withStrokeColor(strokeColor: string) {
        this.strokeColor = strokeColor;
        return this;
    }

    withFillColor(fillColor: string) {
        this.fillColor = fillColor;
        return this;
    }

    withCenter(center: Coords) {
        this.center = center;
        this.type = GeofenceType.Circle;
        return this;
    }

    withPoints(points: Coords[]) {
        this.points = points;
        this.type = GeofenceType.Polygon;
        return this;
    }

    build(): Shape {
        return new Shape(
            this.id,
            this.type,
            this.radius,
            this.precision,
            this.strokeColor,
            this.fillColor,
            this.center,
            this.points
        );
    }
}

export const renderShapes = (...shapes: Shape[]) => {
    sendMessage(widgetActions.renderShapes({ shapes, context: MapContext.MAP_CONTEXT_DEFAULT }));
};

export const clearShapes = () => renderShapes();
