import { styles } from './StyleSheet';
import { Text, View } from '@react-pdf/renderer';
import {
    AdditionalStopSection,
    ArrivalSection,
    ChargingStationSection,
    DepartureSection,
    DrivingSection,
    RouteSection,
    SectionType,
} from '../../store/facade/facadeApi';
import { convertMetersIntoKilometers, convertSecondsIntoHHmm, convertStringToDateFormat } from '../../common/utils';
import { Unit } from '../../common/constants';
import { uniqueId } from 'lodash/fp';

type TripSection = {
    sectionType: string;
    keyValues: [key: string, value: string][];
};

export const generateTripSection = ({ sectionType, keyValues }: TripSection) => {
    return (
        <View style={styles.section} key={uniqueId('')}>
            <Text style={styles.subTitle}>{sectionType}</Text>
            {keyValues.map((it) => (
                <View style={styles.sectionItem} key={uniqueId('')}>
                    <Text style={styles.sectionColumn}>{it[0]}</Text>
                    <Text>{it[1]}</Text>
                </View>
            ))}
        </View>
    );
};

type Props = {
    tripSections: RouteSection[];
};
export const TripSectionsPage = ({ tripSections }: Props) => {
    return (
        <>
            <Text style={styles.title}>Trip sections</Text>
            <View>
                {tripSections?.map((section) => {
                    switch (section.sectionType) {
                        case SectionType.Departure:
                            const departure = section.info as DepartureSection;
                            const departureDate = convertStringToDateFormat(departure.departure_time);

                            return generateTripSection({
                                sectionType: 'Departure',
                                keyValues: [
                                    ['Date', departureDate ?? '-'],
                                    ['Address', departure.address],
                                ],
                            });
                        case SectionType.Arrival:
                            const arrival = section.info as ArrivalSection;
                            const arrivalDate = convertStringToDateFormat(arrival.estimated_arrival_time);
                            return generateTripSection({
                                sectionType: 'Arrival',
                                keyValues: [
                                    ['Date', arrivalDate ?? '-'],
                                    ['Address', arrival.address],
                                ],
                            });

                        case SectionType.Driving:
                            const driving = section.info as DrivingSection;
                            const drivingDate = convertStringToDateFormat(driving.estimated_departure);
                            const distance = convertMetersIntoKilometers(driving.distance);
                            return generateTripSection({
                                sectionType: 'Driving',
                                keyValues: [
                                    ['Date', drivingDate ?? '-'],
                                    ['Distance', `${distance} ${Unit.km}`],
                                ],
                            });

                        case SectionType.AdditionalStop:
                            const stop = section.info as AdditionalStopSection;
                            const stopDate = convertStringToDateFormat(stop.estimated_arrival_time);
                            const duration = convertSecondsIntoHHmm(stop.duration);
                            return generateTripSection({
                                sectionType: 'Additional Stop',
                                keyValues: [
                                    ['Duration', duration ?? '-'],
                                    ['Date', stopDate ?? '-'],
                                    ['Address', stop.address],
                                ],
                            });
                        case SectionType.ChargingStation:
                            const station = section.info as ChargingStationSection;
                            const stationDuration = convertSecondsIntoHHmm(station.duration);
                            const stationDate = convertStringToDateFormat(station.estimated_arrival_time);
                            const operator = station.charging_station.operator.name;
                            return generateTripSection({
                                sectionType: 'Additional Stop',
                                keyValues: [
                                    ['Duration', stationDuration ?? '-'],
                                    ['Date', stationDate ?? '-'],
                                    ['Operator', operator ?? '-'],
                                    ['Address', station.charging_station.address ?? '-'],
                                ],
                            });
                    }
                })}
            </View>
        </>
    );
};
