import { isUndefined } from 'lodash';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import map from 'lodash/fp/map';
import slice from 'lodash/fp/slice';
import { IntlShape } from 'react-intl';
import { fetchData } from '../../configuration/setup/fetch';
import { RootState } from '../../configuration/setup/store';
import { selectHereSettings } from '../../store/data/dataSelectors';
import { selectLocale } from '../../store/lang/langSelectors';
import {
    Address,
    WaypointType,
    fetchSuggestedAddressesFailed,
    suggestedAddressesChanged,
} from '../../store/search/searchSlice';
import { fetchChargingStationsNearAddresses } from './fetchChargingStations';
import { searchGeofenceAddresses } from './searchGeofenceAddresses';
import { searchPoiAddresses } from './searchPoiAddresses';

const AUTO_SUGGEST_ENDPOINT = 'https://autosuggest.search.hereapi.com/v1/autosuggest';

const withPosition = (address: Address) => !isEmpty(address.position);

const mapType = (address: Address) => ({
    ...address,
    type: WaypointType.ADDITIONAL_STOP,
});

export const processAddresses = flow(filter(withPosition), slice(0, 5), map(mapType));

export const fetchAddress = async (
    value: string,
    currentMapCenter: any,
    locale: string,
    apikey: string
): Promise<Address[]> => {
    const query = value ? `q=${encodeURIComponent(value)}` : '';

    if (query.length === 0) {
        throw new Error('No query parameters.');
    }

    const at = currentMapCenter ? `at=${currentMapCenter.lat},${currentMapCenter.lng}` : '';

    const url = `${AUTO_SUGGEST_ENDPOINT}?${at}&${query}&apiKey=${apikey}`;
    const response = await fetchData(url, undefined, {
        headers: {
            'Accept-Language': locale,
        },
    });
    return processAddresses(response.items);
};

export const fetchAddresses =
    (
        id: number,
        value: string,
        isFirstWaypoint: boolean,
        vehiclePosition: { lat?: number; lng?: number } | undefined,
        intl: IntlShape
    ) =>
    async (dispatch: Function, getState: () => RootState) => {
        try {
            const apikey = selectHereSettings(getState())?.apikey;
            if (isNil(apikey)) {
                return;
            }

            const locale = selectLocale(getState());
            if (isUndefined(locale)) {
                return;
            }

            const currentMapCenter = {
                lat: 48.13642,
                lng: 11.57755,
            };

            const processedAddresses = await fetchAddress(value, currentMapCenter, locale, apikey);
            const chargingStationsNearAddresses = await fetchChargingStationsNearAddresses(
                processedAddresses,
                dispatch
            );

            const poiAddresses = searchPoiAddresses(getState, value);
            const geofenceAddresses = searchGeofenceAddresses(getState, value);
            const allAddresses = [
                ...processedAddresses,
                ...chargingStationsNearAddresses,
                ...poiAddresses,
                ...geofenceAddresses,
            ];

            if (isFirstWaypoint && vehiclePosition) {
                allAddresses.unshift({
                    type: WaypointType.VEHICLE_LOCATION,
                    title: intl.formatMessage({ id: 'intl-msg:smartRoute.tour.useVehicleLocation' }),
                    resultType: WaypointType.VEHICLE_LOCATION,
                    position: { lat: vehiclePosition.lat!, lng: vehiclePosition.lng! },
                });
            }

            dispatch(suggestedAddressesChanged({ id, addresses: allAddresses }));
        } catch (error) {
            if (error instanceof Error) {
                dispatch(fetchSuggestedAddressesFailed(error.message));
            }
        }
    };
