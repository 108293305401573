import { http, HttpResponse } from 'msw';
import { API } from '../../../src/common/constants';
import { config } from '../../../src/configuration/config';
import { generateFeatureTooglesMock } from '../data/featureTooglesMock';

// eslint-disable-next-line max-len
const URL = `${config.backend.BACKEND_SERVICE}/${API.serviceName}/${API.version.latest}${API.endpoints.featureToggles}`;

export default http.get(URL, () => {
    const featureToogles = generateFeatureTooglesMock();
    return HttpResponse.json(featureToogles);
});

export const featureTooglesErrorResponse = http.get(URL, () => {
    return HttpResponse.json({ errorMessage: 'Mock feature toogles response error' }, { status: 400 });
});
