import Fuse from 'fuse.js';
import { flow, map, slice } from 'lodash/fp';
import { MAX_AUTO_SUGGEST_ITEMS } from '../../common/constants';
import { RootState } from '../../configuration/setup/store';
import { selectPois } from '../../store/data/dataSelectors';
import { Address } from '../../store/search/searchSlice';
import { Poi } from './mappers/mapPoi';

const mapAddress = (poi: Poi): Address => ({
    title: `${poi.name ?? ''} ${poi.address ?? ''}`.trim(),
    type: poi.type.toLowerCase(),
    resultType: poi.type.toLowerCase(),
    position: { lat: poi.coordinates.latitude, lng: poi.coordinates.longitude },
});

const processAddresses = flow(slice(0, MAX_AUTO_SUGGEST_ITEMS), map(mapAddress));

export const searchPoiAddresses = (getState: () => RootState, value: string) => {
    const pois = selectPois(getState());
    const options = {
        keys: ['name', 'address'],
        threshold: 0.2,
        distance: 30,
    };
    const fuse = new Fuse(pois, options);
    const results = fuse.search(value);
    return processAddresses(results.map((result) => result.item));
};
